import { LocalService } from './../../services/local.service';
import { Component, OnInit } from '@angular/core';
import { Local } from 'src/app/model/Local';
import {DomSanitizer} from '@angular/platform-browser';


@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  local: Local;

  constructor(public localService: LocalService,
    private sanitizer:DomSanitizer) { }

  ngOnInit() { 
    this.localService.localSeleccionado.subscribe(l => {
      this.local = l;
    });
  }

  getWhatsapp() {
    return this.sanitizer.bypassSecurityTrustUrl(`whatsapp://send?phone=${this.local.telefono}`);
  }
}
