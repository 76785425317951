import { animate, query, stagger, style, transition, trigger } from '@angular/animations'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Router } from '@angular/router'
import { ModalController } from '@ionic/angular'
import { Subscription } from 'rxjs'
import { debounceTime, filter } from 'rxjs/operators'
import { ItemPedido } from 'src/app/model/ItemPedido'
import { MenuDelDia } from 'src/app/model/MenuDelDia'
import { ProductoService } from 'src/app/services/producto.service'
import { DEFAULT_IMAGE } from 'src/assets/data/constants'
import { environment } from './../../../environments/environment'
import { Categoria } from './../../model/Categoria'
import { Pedido } from './../../model/Pedido'
import { ProductoCabecera } from './../../model/ProductoCabecera'
import { Restriccion } from './../../model/Restriccion'
import { LocalService } from './../../services/local.service'
import { MenuService } from './../../services/menu.service'
import { PedidoService } from './../../services/pedido.service'
import { ProductoPresenterService } from './../../services/producto-presenter.service'

@Component({
    selector: 'product-item',
    templateUrl: './product-item.component.html',
    styleUrls: ['./product-item.component.scss'],
    animations: [
        trigger('showUp', [
            transition('* <=> *', [
                query(
                    ':enter',
                    [
                        style({ opacity: 0, transform: 'translateY(-10px)' }),
                        stagger('70ms', animate('600ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))),
                    ],
                    { optional: true }
                ),
                query(':leave', animate('200ms', style({ opacity: 0 })), {
                    optional: true,
                }),
            ]),
        ]),
    ],
})
export class ProductItemComponent implements OnInit, OnDestroy {
    @Input()
    public sentidoScroll: 'v' | 'h' = 'v'
    public restriccionesExcluyentes: Restriccion[] = []
    public slideOptions:any = {}
    public enPromocion: ItemPedido[] = []
    public favorito: boolean = true
    public pedido: Pedido
    public layout: string = environment.layoutItem
    @Input()
    public esDisponible: boolean = false
    

    @Input()
    public menu: MenuDelDia
    private _categoria: Categoria
    public get categoria(): Categoria {
        return this._categoria
    }
    @Input()
    public set categoria(v: Categoria) {
        this._categoria = v
        //this.filter(this.buscador?.value ? this.buscador.value : null)
    }

    private sub: Subscription
    public opciones: ItemPedido[] = []
    private _buscador: FormControl
    public get soloMenu() {
        return this.pedidoService.soloMenu()
    }
    public get buscador(): FormControl {
        return this._buscador
    }
    @Input()
    public set buscador(v: FormControl) {
        this._buscador = v
        this.suscribeFilter()
    }

    private _restricciones: Restriccion[] = []
    public get restricciones(): Restriccion[] {
        return this._restricciones
    }
    @Input()
    public set restricciones(v: Restriccion[]) {
        this._restricciones = v.filter((r) => r.seleccionada && !r.excluyente)
        this.restriccionesExcluyentes = v.filter((r) => r.seleccionada && r.excluyente)
        this.suscribeFilter()
    }



    private suscribeFilter() {
        if (this.sub) this.sub.unsubscribe()
        this.sub = this._buscador.valueChanges
            .pipe(
                debounceTime(700),
                filter((v) => v != undefined)
            )
            .subscribe((r: string) => {
                this.filter(r)
            })
        this.filter(this.buscador?.value ? this.buscador.value : null)
    }
    private delay
    private updateCantidad(){
        if(this.pedido)
        this.opciones.forEach(i=> i.cantidad = this.pedido.getCantidad(i.producto))
    }
    private filter(r: string) {
        let terminos = (r ? r.split(' ') : []).filter((v) => v?.length > 0).map((v) => v.toUpperCase())
        let res = this.categoria.items.filter((i) => i.visible)
        this.enPromocion = this.categoria.items.filter((i) => i.descuentoAplicado)

        if (terminos.length != 0) {
            terminos.forEach((s) => {
                res = res.filter((o) => !s || (o.producto && (o.producto.descripcion?.toUpperCase().includes(s) || o.producto.categorias?.includes(s))))
            })
        }
        
        if (this.restricciones?.length) {
            res = res.filter(prod => prod.aplicaRestricciones(this.restricciones, this.restriccionesExcluyentes))
          
        }
        const top = res.length > 10 ? 10 : res.length
        this.opciones = res.slice(0, top)
        if (!this.delay) {
            this.delay = setTimeout(() => {
                if (top + 1 < res.length) {
                    res.slice(top + 1, res.length).forEach((p) => {
                        this.opciones.push(p)
                    })
                }
                this.delay = undefined
                this.updateCantidad()
            })
        }
    }
    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe()
    }
    constructor(
        private router: Router,
        private pedidoService: PedidoService,
        private menuService: MenuService,
        private localService: LocalService,
        private modalController: ModalController,
        public productoService: ProductoService,
        public productoPresenter: ProductoPresenterService
    ) {}

    public agregarProducto(event, item) {
        event.stopPropagation()
        item.cantidad++
        this.pedidoService.agregarProducto(item)
    }

    public get admitePedido() {
        return this.localService.local && this.localService.local?.admitePedido && !environment.soloMenu
    }
    public getImagen(producto: ProductoCabecera) {
        return producto.imagenPortada || this.localService?.local?.imagen?.picPath || DEFAULT_IMAGE
    }
    isMobile() {
        return window.innerWidth <= 640
    }
    ngOnInit() {
       
           this.slideOptions ={
            slidesPerView : 1,
            spaceBetween: 10,
            centerInsufficientSlides: true,
            autoplay:true,
            delay:15000,
            breakpoints:{
                640:{
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                920:{
                    slidesPerView: 3,
                    spaceBetween: 10
                },
                1200:{
                    slidesPerView: 4,
                    spaceBetween: 10
                }
            }              

           }
       
        this.suscribeFilter()
        this.pedidoService.pedido.subscribe((p) => {
            this.pedido = p
            this.updateCantidad()
        })
        this.pedidoService.onItemsChange.pipe(filter((item) => item != undefined)).subscribe((item) => {
            const data = this.opciones.find((i) => i.producto.id == item.producto.id)
            if (data) 
            data.cantidad = item.cantidad
        })

        if (this.menu?.configuracion?.tipoLayout) {
            this.layout = this.menu.configuracion.tipoLayout.codigo
        }
    }
}
