import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Mesa } from '../model/Mesa';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class MesaService extends BaseService {
    constructor(http: HttpClient) {
        super(http)
    }
    public baseName(): string {
        return "mesa";
    }


    public localMesas = new Map<number, Mesa[]>();


    public mesaSeleccionadaObs: BehaviorSubject<Mesa> = new BehaviorSubject(null);
    private _mesaSeleccionada: Mesa;
    public get mesaSeleccionada(): Mesa {
        return this._mesaSeleccionada;
    }
    public set mesaSeleccionada(v: Mesa) {
        this._mesaSeleccionada = v;
        this.mesaSeleccionadaObs.next(v);
    }
    public seleccionarMesa(idMesa, idLocal): Promise<Mesa> {
        return this.getByLocal(idLocal, idMesa).then(r => {
            this.mesaSeleccionada = r;
            return r;
        });
    }
    public getByLocal(idLocal: number, idMesa: number): Promise<Mesa> {
        return this.http.get(this.getBaseURL("byLocal/") + idLocal + "/" + idMesa).toPromise().then((r: any) => {
            return r;
        })
    }

    public getListByLocal(idLocal: number): Promise<Mesa[]> {

        const mesas = this.localMesas.get(idLocal)

        if (!mesas || !mesas.length) {
            return this.http.get(this.getBaseURL("byLocal/") + idLocal).toPromise().then((r: any) => {
                this.localMesas.set(idLocal,r)
                return r;
            })
        } else
            return new Promise<Mesa[]>((resolve, reject) => { resolve(mesas)})
    }

}