import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextService } from 'src/app/services/context.service';
import { PedidoService } from 'src/app/services/pedido.service';
import { Promocion } from './../../model/Promocion';

@Component({
  selector: 'promocion-detalle',
  templateUrl: './promocion-detalle.component.html',
  styleUrls: ['./promocion-detalle.component.scss'],
})
export class PromocionDetalleComponent implements OnInit, OnDestroy {

  constructor(private contextService: ContextService,
    private pedidoService: PedidoService,
    private router: Router) {

  }

  private _promocion: Promocion;
  public get promocion(): Promocion {
    return this._promocion;
  }
  public set promocion(v: Promocion) {
    this._promocion = v;

  }
  viewOrder(): void {
    this.pedidoService.pedidoSeleccionado = this.pedidoService.pedidoActual;
    this.router.navigate(['/pedido'])
  }
  ngOnInit() {
    this.promocion = this.contextService.get("promo");

  }
  ngOnDestroy() {

  }


}
