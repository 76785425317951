// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    //apiUrl: 'assets/data'
    apiUrl: 'https://santomaridaje-back.costazini.com.ar/santomaridaje-backend/',
    //apiUrl: "https://api.santomaridaje.ar/santomaridaje-backend/",
    tokenKey: 'santomaridaje-backend-dev',
    userApp: 'appuser',
    passwordApp: 'admin1234',
    firebaseConfig: {
        apiKey: 'AIzaSyAMAvQ4GHdjud6QJ6bAgI_Jr6l79TpRX-s',
        authDomain: 'santomaridaje-test-app.firebaseapp.com',
        databaseURL: 'https://santomaridaje-test-app.firebaseio.com',
        projectId: 'santomaridaje-test-app',
        storageBucket: 'santomaridaje-test-app.appspot.com',
        messagingSenderId: '719850476154',
        appId: '1:719850476154:web:65c8ddc5a8c15151b261c3',
    },
    layoutItem: 'H',
    soloMenu: false,
    
    localDefaut:null,
    conMesas: false,
    requiereValidacionEdad: true,
    pedidosExternos: false,
    admiteReservas:true,
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
