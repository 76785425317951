import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { MenuService } from 'src/app/services/menu.service';
import { ProductoPresenterService } from 'src/app/services/producto-presenter.service';
import { environment } from 'src/environments/environment';
import { PedidoPage } from './../../pages/pedido/pedido.page';
import { LocalService } from './../../services/local.service';
import { NavigationService } from './../../services/navigation.service';
import { PedidoService } from './../../services/pedido.service';
import { AuthService } from 'src/app/services/auth.service';
//import { Usuario } from '../../model/Usuario';

@Component({
  selector: 'topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {

  constructor(
    public pedidoService: PedidoService, 
   // private router: Router,
    private navigationService: NavigationService,
    private modalController: ModalController,
    private productoPresenter: ProductoPresenterService,
   // private menuService: MenuService, 
    private localService: LocalService,
   // private authService: AuthService 
   ) { }
  @Input()
  public showBack: boolean = false;
  @Input()
  public showMenu: boolean = true;
  @Input()
  public showCloseModal: boolean = false;
  @Input()
  public defaultBack: string = 'menu';
  @Input()
  public title: string;
  @Input()
  public showCart: boolean = true;
  public soloMenu = environment.soloMenu;
  public hayPedido: boolean = false;
  public cantidadItems: number = 0;
  public total: number = 0;
  public animar = false;
  //public usuario: Usuario;


  ngOnInit() {

    this.pedidoService.pedido.subscribe(p => {
      if (p) {
        if (this.cantidadItems != p.cantidadItems) {
          this.animar = true;
          setTimeout(() => {
            this.animar = false;
          }, 400)
        }
        this.cantidadItems = p.cantidadItems;
        this.hayPedido = this.cantidadItems > 0;
        this.total = p.getTotal();
      } else {
        this.hayPedido = false;
        this.cantidadItems = 0;
        this.total = 0;
      }
    })

      
    

  }
  public close = () => {
    this.productoPresenter.close();

  }
  public goToHome() {
    this.navigationService.goToHome();
    if (this.showCloseModal) {
      this.close();
    }
  }
  public getTopbarImage() {
    return this.localService?.local?.imagenHeaderUrl || "../../../assets/implementacion/images/top-logo.jpeg";
  }

  public getNombreLocal(){
    return this.localService?.local?.descripcion
  }
  public admitePedido() {
    return this.localService.local && this.localService.local.admitePedido && !environment.soloMenu;
  }

  async viewOrder() {
    this.pedidoService.pedidoSeleccionado = this.pedidoService.pedidoActual;

    const modal = await this.modalController.create({
      component: PedidoPage,
      cssClass: 'pedido-modal',
      componentProps: {
        isModal: true
      }

    });
    return await modal.present();


  }
}
