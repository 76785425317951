import { Filtro } from './../model/Filtro';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Restriccion } from '../model/Restriccion';

@Injectable({
  providedIn: 'root'
})
export class RestriccionService extends BaseService{
  public baseName(): string {
    return 'restriccion'
}
  constructor(        http: HttpClient
    ) {
    super(http);
  }

  public getHabilitados(f?: Filtro): Promise<Restriccion[]> {

    if (!f) {
      f = new Filtro('Restricciones',{})
    }

    return this.http
    .post(this.getBaseURL() + 'habilitados', f.json)
    .toPromise()
    .then((r) => {
        return r
    })
    .catch((e) => {
        return this.handleError(e)
    })

  }
}
