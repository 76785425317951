import { Injectable } from '@angular/core';
import { Vibration } from '@ionic-native/vibration/ngx';
import { Platform } from '@ionic/angular';
@Injectable({
    providedIn: "root"
})
export class VibrationService {
    private vibrating: boolean;
    private counter;
    private laps: number = 0;
    constructor(private vibration: Vibration,
        private platform: Platform) {

    }
    public stopVibration() {
        this.laps = 100;
        if (this.counter)
            clearInterval(this.counter);
    }
    public vibrate() {
        if (!this.vibrating) {

            this.vibrating = true;
            this.doVibration();
            this.counter = setInterval(() => {
                if (this.laps >= 6) {
                    clearInterval(this.counter);

                } else {
                    this.doVibration();

                }


            }, 10000);
        }
    }

    private doVibration() {
        if (this.platform.is("mobileweb")) {
            window.navigator.vibrate([3000, 3000, 3000]);

        } else {
            this.vibration.vibrate([3000, 3000, 3000]);
        }
        this.laps++;
    }
}
