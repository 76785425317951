import { NavigationService } from './../../services/navigation.service';

import { environment } from 'src/environments/environment';
import { map, catchError, first } from 'rxjs/operators';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { MenuController, ToastController } from '@ionic/angular'
import { combineLatest, Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'
import { Local } from './../../model/Local'
import { Menu } from './../../model/Menu'
import { MenuDelDia } from './../../model/MenuDelDia'
import { LocalService } from './../../services/local.service'
import { MenuService } from './../../services/menu.service'
import { PedidoService } from './../../services/pedido.service'

@Component({
    selector: 'app-landing',
    templateUrl: './landing.page.html',
    styleUrls: ['./landing.page.scss'],
    animations: [
        trigger('showUp', [
            transition('* <=> *', [
                query(
                    ':enter',
                    [
                        style({ opacity: 0, transform: 'translateY(-10px)' }),
                        stagger('90ms', animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))),
                    ],
                    { optional: true }
                ),
                query(':leave', animate('200ms', style({ opacity: 0 })), { optional: true }),
            ]),
        ]),
    ],
})
export class LandingPage implements OnInit, OnDestroy {
    loading: Boolean
    public menus: Menu[] = []
    public locales: Local[] = []
    public localesConMenu: Local[] = []
    public localesConReserva: Local[] = []
    public local: Local
    public loadingMenu: boolean;
    public routerSub : Subscription;
    public searchStr: string ="";
    constructor(
        private router: Router,
        private menuController: MenuController,
        private menuService: MenuService,
        private localService: LocalService,
        private pedidoService: PedidoService,
        private toastController:ToastController,
        private route : ActivatedRoute,
        private navigationService: NavigationService
    ) {}

    ionViewWillEnter() {
        this.menuController.enable(true)
    }

    viewOrder(): void {
        this.pedidoService.pedidoSeleccionado = this.pedidoService.pedidoActual
        this.router.navigate(['/pedido'])
    }
    public subs: Subscription[] = []
    ngOnDestroy() {
        if (this.subs) this.subs.forEach((s) => s.unsubscribe())
    }
    public localElegido: boolean = false
    public elegirLocal(l: Local) {       
        this.updateMenu(l)
    }
    private updateMenu(l: Local) {
        if (!l) return Promise.resolve([]);
        this.menus = []
        this.loading = true
        this.local = l;
        return this.menuService
            .getMenus(l)
            .then((r) => {
                this.menus = r
                this.localService.local = l;
               
                if (r.length == 1 && !l.admiteReserva) {
                    
                    return this.verMenu(r[0]).then(l=>{
                        this.loading = false
                        return l;
                    })
                }else{
                    this.loading = false
                }
                this.localElegido = true

                return r;
            })
            .catch((e) => {
                this.localElegido = true
                this.loading = false
                return Promise.resolve([])
            })
    }
    init = async () => {
        if(environment.localDefaut){
            this.getByIdentificacion(environment.localDefaut);
            return;
        }
        this.route.params.pipe(map(params =>{ return params['identificadorUrl']}),filter(i => i != null),first()).subscribe( identificadorUrl=>{
                  const localIdentificador = identificadorUrl || environment.localDefaut;
                  if(localIdentificador && this.local?.identificadorUrl != localIdentificador){
                      this.getByIdentificacion(localIdentificador);
                  }
              })
       
        
    }
    getByIdentificacion = (localIdentificador)=>{
        this.localService.getByIdentificador(localIdentificador).then(l=>{
            this.elegirLocal(l)
        }).catch(e=>{
            this.toast("El local seleccionado no existe");
            this.loading = false;
        })
    }
    ngOnInit() {
        this.init()
    }
    public buscarLocales(event){
        const searchStr = event?.detail?.value || "";
        if(searchStr.length <3){
            this.localesConMenu = [];
            return
        } ;
        
        this.loading = true
        this.localService.getLocales(searchStr).then(l=>{
            this.localesConMenu = l;                
            this.loading = false;
        }).catch(e =>{
            this.loading = false;
            this.localesConMenu =[]
        })
       }
    public ionViewDidEnter() {
       /* if (this.localesConMenu?.length == 1 && !this.localService.admiteReservas) {
            this.localService.local = this.localesConMenu[0]
            this.updateMenu(this.localService.local)
        }*/
    }
    public volverALocales() {
        this.localElegido = false
        
    }
    async toast(m: string) {
		const t = await this.toastController.create({
		  message: m,
		  duration: 3000,
		  position: "middle"
		});
		t.present();
	  }
    public async verMenu(m: MenuDelDia | Menu) {
        if(this.loadingMenu) return ;
        if (!this.local) return Promise.resolve([])
        this.loadingMenu = true;
        this.menuService
            .seleccionarMenu(m.id, this.local.id)
            .then((m) => {
                if(m?.id){
                    this.router.navigate(["/home"],  { queryParams: { idLocal: this.local.id, idMenu: m.id } });
                }else {
                    this.localService.seleccionarLocal(null);
                    this.toast("El menú no se encuentra disponible")
                }
                this.localElegido = false
                this.loadingMenu = false;
            })
            .catch((e) => {
                this.localElegido = false                
                this.loadingMenu = false;
            })
        return Promise.resolve([])
    }
    public irAReservas(l: Local) {
        this.localService.local = l
        this.router.navigate(['nueva-reserva'])
    }
}
