import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class TranslateService {
  data: any = {};
  dataCodebase: any = {};
  public idioma: string = 'es'
  constructor(private http: HttpClient) {


  }
  get(key: string) {
    let valor = this.data[key] ? this.data[key] : null;
    if (valor)
      return valor;
    return this.dataCodebase[key] ? this.dataCodebase[key] : key;
  }
  getOrKey(key: string) {
    let valor = this.data[key] || key;
    return valor;
  }
  upateData: BehaviorSubject<Boolean> = new BehaviorSubject(null);

  use(lang: string): Promise<{}> {
    //this.idioma = lang;
    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/implementacion/i18n/${lang || 'es'}.json`;
      this.http.get<{}>(langPath).subscribe(
        translation => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
          this.upateData.next(true);
          this.idioma = lang;
        },
        error => {
          this.data = {};
          if (lang == "es") {
            resolve(this.data);
            
          } else {
            this.use("es");
            
          }
        }
      );
    });
  }

  cargarDataCodebase(lang: string): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/${lang || 'es'}.json`;
      this.http.get<{}>(langPath).subscribe(
        translation => {
          this.dataCodebase = Object.assign({}, translation || {});
          resolve(this.dataCodebase);
          this.upateData.next(true);
        },
        error => {
          this.dataCodebase = {};
          if (lang == "es") {
            resolve(this.dataCodebase);
            
          } else {
            this.cargarDataCodebase("es");
            
          }
        }
      );
    });
  }
}



