import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { QRScanner } from '@ionic-native/qr-scanner/ngx'
import { AlertController } from '@ionic/angular'
import * as moment from 'moment'
import { filter, first } from 'rxjs/operators'
import { Descriptivo } from 'src/app/model/Descriptivo'
import { Menu } from 'src/app/model/Menu'
import { MenuDelDia } from 'src/app/model/MenuDelDia'
import { Pedido } from 'src/app/model/Pedido'
import { PedidoService } from 'src/app/services/pedido.service'
import { Local } from './../../model/Local'
import { LocalService } from './../../services/local.service'
import { MenuService } from './../../services/menu.service'
import { MesaService } from './../../services/mesa.service'
import { NavigationService } from './../../services/navigation.service'
@Component({
    selector: 'app-mi-mesa',
    templateUrl: './mi-mesa.component.html',
    styleUrls: ['./mi-mesa.component.scss'],
})
export class MiMesaComponent implements OnInit {
    menuElegido: boolean = false
    public menus: Menu[] = []
    constructor(
        private activeRoute: ActivatedRoute,
        private localService: LocalService,
        private menuService: MenuService,
        private navigationService: NavigationService,
        private qrScanner: QRScanner,
        private alertController: AlertController,
        private mesaService: MesaService,
        private pedidoService: PedidoService,
        private alertCtrl: AlertController
    ) {}

    public get locacion(): Local {
        return this.localService.locacion
    }
    public set locacion(v: Local) {
        this.localService.locacion = v
    }

    public get numeroMesa(): string {
        return this.mesaService?.mesaSeleccionada?.codigo
    }
    public set numeroMesa(v: string) {}

    public error: string
    private sub
    public estadoMesa: {
        llamadaMozo: Date
        cuentaCerrada: boolean
    } = { llamadaMozo: null, cuentaCerrada: false }
    public scanSub

    public permiteLlamado() {
        return !this.estadoMesa.llamadaMozo || moment(this.estadoMesa.llamadaMozo).diff(new Date(), 'minutes') > 5
    }

    ngOnInit() {
        this.error = ''
        this.pedidoService.ready
            .pipe(
                filter((s) => s == true),
                first()
            )
            .subscribe(() => {
                if (!this.sub) {
                    this.sub = this.activeRoute.queryParams.subscribe((p) => {
                        const idMesa = p['mesa']
                        const idLocal = p['local']
                        const idLocacion = p['locacion']
                        const idMenu = p['menu']
                        if (idLocal) {
                            this.localService.seleccionarLocal(idLocal).then( l => {
                              this.updateMenu(l)
                            })
                        }
                        if (idMenu && idLocacion) {
                            this.menuService.seleccionarMenu(idMenu, idLocal).then(() => this.menuElegido = true)
                        } 
                        if (idLocacion) {
                            this.localService.seleccionarLocacion(idLocacion)
                        }

                        if (idMesa && idLocal && idMenu) {
                          this.asignarMesa(idMesa, idLocal, idMenu)
                        }

                        if (idMesa && idLocal && !idMenu) {
                          this.mesaService.seleccionarMesa(idMesa, idLocal).then((r) => {
                            if (!r) {
                                this.error = 'No se encuentra la mesa buscada. Por favor consulte con el personal.'
                            }
                        })                        }

                    })
                }
            })
    }
    public goToHome() {
        this.navigationService.goToHome()
    }
    public activarScanner() {
        this.scanSub = this.qrScanner.scan().subscribe((text: string) => {
            this.qrScanner.hide() // hide camera preview
            this.scanSub.unsubscribe() // stop scanning
        })
    }
    stopScanning() {
        this.scanSub ? this.scanSub.unsubscribe() : null
        this.scanSub = null
        //(window.document.querySelector('ion-app') as HTMLElement).classList.remove('cameraView');
        this.qrScanner.hide()
        this.qrScanner.destroy()
    }
    public async llamarAlMozo() {
        const a = await this.alertController.create({
            header: 'Llamar',
            buttons: [
                {
                    text: 'OK',
                    handler: () => {
                        this.estadoMesa.llamadaMozo = new Date()
                    },
                },
                { text: 'Cancelar', role: 'cancel' },
            ],
        })
        return await a.present()
    }

    public async pedirLaCuenta() {
        const a = await this.alertController.create({
            header: 'Cerrar la cuenta',
            message: 'Esto cerrará tu cuenta. La cerramos?',
            buttons: [
                {
                    text: 'OK',
                    handler: () => {
                        this.estadoMesa.cuentaCerrada = true
                    },
                },
                { text: 'Cancelar', role: 'cancel' },
            ],
        })
        return await a.present()
    }
    public clean() {
        this.locacion = null
        this.mesaService.mesaSeleccionada = null
        this.estadoMesa = { llamadaMozo: null, cuentaCerrada: false }
        this.pedidoService.pedidoActual.mesaId = null;
        this.pedidoService.updatePedido(this.pedidoService.pedidoActual)
    }

    async asignarMesa(idMesa: any, idLocal, idMenu) {
        let pedido = this.pedidoService._pedidos?.find((p: Pedido) => p.local.id == idLocal && p.menuId == idMenu)
        if (pedido) {
            if (!pedido.mesaId) {
                pedido.mesaId = idMesa
                this.pedidoService.updatePedido(pedido)
                this.mesaService.seleccionarMesa(idMesa, idLocal).then((r) => {
                  if (!r) {
                      this.error = 'No se encuentra la mesa buscada. Por favor consulte con el personal.'
                  }
              })
            } else if (pedido.mesaId != idMesa) {
                const alert = await this.alertCtrl.create({
                    message: `Tenés un pedido que aún no finalizó ¿Qué querés hacer?`,
                    buttons: [
                        {
                            text: 'Cancelar',
                            role: 'cancel',
                        },
                        {
                            text: 'Cambiar Mesa',
                            handler: async () => {
                                pedido.mesaId = idMesa
                                this.pedidoService.updatePedido(pedido)
                                this.mesaService.seleccionarMesa(idMesa, idLocal).then((r) => {
                                  if (!r) {
                                      this.error = 'No se encuentra la mesa buscada. Por favor consulte con el personal.'
                                  }
                              })
                            },
                        },
                    ],
                })
                alert.present()
            } else {
              this.mesaService.seleccionarMesa(idMesa, idLocal).then((r) => {
                if (!r) {
                    this.error = 'No se encuentra la mesa buscada. Por favor consulte con el personal.'
                }
            })
            }
        } else {
            await this.crearNuevoPedido(idMesa, idMenu, idLocal)
            this.mesaService.seleccionarMesa(idMesa, idLocal).then((r) => {
              if (!r) {
                  this.error = 'No se encuentra la mesa buscada. Por favor consulte con el personal.'
              }
          })
        }
    }

    private async crearNuevoPedido(idMesa, idMenu, idLocal) {
      let p = new Pedido()
      p.mesaId = idMesa
      p.menuId = idMenu
      p.tipoEntrega = new Descriptivo('M', 'En Mesa')
      p.local = await this.localService.getById(idLocal)
      // this.pedidoService.pedidoActual = p
      this.pedidoService.updatePedido(p)
  }

  public async verMenu(m: MenuDelDia | Menu) {
    this.menuService
        .seleccionarMenu(m.id, (await this.localService.getSeleccionado()).id)
        .then((menu) => {
          this.menuElegido = true;
        })
        .catch((e) => {
            this.menuElegido = false
        })
}


private updateMenu(l: Local) {
  if (!l) return
  this.menus = []
  this.menuService
      .getMenus(l)
      .then((r) => {
          this.menus = r

          if (r.length == 1) {
              this.verMenu(r[0])
          }
      })
      .catch((e) => {
      })
}


}
