import { Injectable } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { ProductoService } from 'src/app/services/producto.service';
import { ProductoPage } from './../pages/producto/producto.page';
@Injectable({
    providedIn: 'root'
})
export class ProductoPresenterService {
    public isShowing: boolean = false;

    constructor(private modalController: ModalController, private productoService: ProductoService, private alert: ToastController) {

    }
    close = async () => {

        this.modalController.dismiss({
            'dismissed': true
        });
        this.productoService.productoSeleccionado = null;
        this.isShowing = false;
    }
    goToProducto = async (event, idProducto: number, idMenu: number, idLocal: number, forceOpen = false) => {
        if (event) {
            event.stopPropagation();
        }
        this.productoService.getProductoById(idProducto, idMenu, idLocal).subscribe(async p => {
            if (!p) {
                const a = this.alert.create({
                    header: "No se encontró el producto",
                    position: "middle",
                    duration: 3000,

                });
                (await a).present();
                (await a).onDidDismiss().then(r => {
                    this.isShowing = false;
                })
                this.isShowing = true;
            } else {
                this.productoService.productoSeleccionado = p;
            }
        });

        const tieneDetalle = this.productoService?.productoSeleccionado?.detalle;
        const tieneImagen = this.productoService?.productoSeleccionado?.imagenPortada != undefined || this.productoService?.productoSeleccionado?.imagenes?.length >0;
        if (forceOpen || !(await this.modalController.getTop())) {
            const modal = await this.modalController.create({
                component: ProductoPage,
                cssClass: 'producto-modal ' + 
                (!tieneDetalle? ' sin-descripcion ' : '') + 
                (!tieneImagen? ' sin-imagen ' : '') ,

            });
            return await modal.present();
        }



    }
}