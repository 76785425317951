import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { fromEvent } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ProductoPresenterService } from 'src/app/services/producto-presenter.service';
import { ProductoService } from 'src/app/services/producto.service';
import { environment } from 'src/environments/environment';
import { Mayor18Service } from './mayor18.service';
import { LoginComponent } from './pages/login/login.component';
import { LocalService } from './services/local.service';
import { MenuService } from './services/menu.service';
import { MesaService } from './services/mesa.service';
import { MessagingService } from './services/messages.service';
import { NavigationService } from './services/navigation.service';
import { NovedadService } from './services/novedades.service';
import { PedidoService } from './services/pedido.service';
import { ReservaService } from './services/reservas.service';
import { TranslateService } from './services/translate.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private pedidoService: PedidoService,
        private router: Router,
        private novedadesService: NovedadService,
        private menuService: MenuService,
        public authService: AuthService,
        private translateService: TranslateService,
        private messagesService: MessagingService,
        private swUpdate: SwUpdate,
        private productoPresenter: ProductoPresenterService,
        private localService: LocalService,
        private productoService: ProductoService,
        private navigationService: NavigationService,
        private mayor18Service: Mayor18Service,
        public reservasService: ReservaService,
        public modalController: ModalController,
        private mesaService: MesaService
    ) {
        this.initializeApp()
        this.translateService.use('es')
    }

    public get mesa() {
        return this.mesaService.mesaSeleccionada
    }
    public selectedIndex = 0
    public appPages = []

    private conReservas: boolean = environment.admiteReservas;
    public puedeCambiar: boolean = true
    public soloMenu: boolean = environment.soloMenu
    private backbuttonSubscription

    public logout() {
        this.pedidoService.cleanAll()
        this.authService.logout()
        this.generarMenu()
        // this.router.navigate(['app-root'])
        this.toLanding()
    }

    public toLanding() {
        this.navigationService.goToHome()
    }
    private generarMenu() {
        this.appPages = []
        //if (!environment.soloMenu) {
        this.appPages.push({
            title: 'Mis pedidos',
            url: '/mis-pedidos',
            name: 'bag-outline',
        })

        //}
        if (this.conReservas) {
            this.appPages.push({
                title: 'Mis Reservas',
                url: '/reservas',
                name: 'calendar-clear-outline',
            })
        }

        this.mesaService.mesaSeleccionadaObs.subscribe((m) => {
            if (m && !this.appPages.find((p) => p.url == '/mi-mesa')) {
                this.appPages.push({
                    title: `Mi Mesa: ${this.mesa.descripcion}`,
                    url: '/mi-mesa',
                    name: 'cafe-outline',
                })
            }
        })

        this.appPages.push({
            title: 'Sobre Nosotros',
            url: '/footer',
            name: 'call',
        })
    }

    cambiarLocal = () => {
        this.localService.local = null
        this.navigationService.goToLanding()
    }
    initializeApp() {
        this.menuService.cleanCache()
        this.localService.cleanCache()
        this.productoService.cleanCache()

        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe((n) => {
                if (confirm('Hay una nueva versión, desea actualizarla?')) {
                    window.location.reload()
                    this.menuService.cleanCache()
                    this.localService.cleanCache()
                    this.productoService.cleanCache()
                }
            })
        }
        const event = fromEvent(document, 'backbutton')
        this.backbuttonSubscription = event.subscribe(async () => {
            const modal = await this.modalController.getTop()
            if (modal) {
                modal.dismiss()
            }
        })

        this.splashScreen.show()
        this.platform.ready().then(async () => {
            this.platform.resume.subscribe((v) => {
                //this.novedadesService.recheck();
            })
            this.platform.backButton.subscribeWithPriority(999, () => {
                if (this.productoPresenter.isShowing) {
                    this.productoPresenter.close()
                } else {
                    window.history.back()
                }
            })
            await this.authService.init()
            this.authService
                .getUserObs()
                .pipe(filter((u) => u != undefined))
                .subscribe((r) => {
                    this.pedidoService.init()
                    // this.reservasService.init()
                })

            if (environment.requiereValidacionEdad) {
                this.mayor18Service.initiated
                    .pipe(
                        filter((v) => v == true),
                        first()
                    )
                    .subscribe((v) => {
                        if (!this.mayor18Service.fuePreguntado()) {
                            this.mayor18Service.ask()
                        }
                    })
            }
            this.messagesService.requestPermission()
            this.messagesService.tokekObs.pipe(filter((t) => t != undefined)).subscribe((t) => {
                this.authService.push = t

                this.messagesService.getMessages().subscribe((m) => {
                    this.novedadesService.nuevoPush(m)
                })
            })

            if (!this.authService.renewToken) {
                this.authService
                    .refreshToken()
                    .toPromise()
                    .then((r) => {
                        //this.router.navigate(["login"]);
                    })
                    .catch((e) => {
                        this.authService.loginDefault()
                    })
            }

            this.statusBar.styleDefault()
            this.splashScreen.hide()
            this.statusBar.overlaysWebView(true)

        })
        this.generarMenu()
    }

    ngOnInit() {
        const path = window.location.pathname

        if (path !== undefined) {
            this.selectedIndex = this.appPages.findIndex((page) => page.url.substring(1).toLowerCase() === path.toLowerCase())
        }
        this.localService.init()
        this.authService.getAutenticateState().subscribe((resp) => {
            this.generarMenu()
        }) //  else this.router.navigate([""]);

        this.splashScreen.hide()
    }

    goToDatos() {
        this.router.navigate(['register'])
    }

    async login() {
        const modal = await this.modalController.create({
            component: LoginComponent,
            cssClass: 'modal-login',
            componentProps: {
                modal: true,
            },
        })
        await modal.present()
    }
    ngOnDestroy() {
        if (this.backbuttonSubscription) this.backbuttonSubscription.unsubscribe()
    }
}
