import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.page.html',
  styleUrls: ['./signup.page.scss'],
})
export class SignupPage implements OnInit {
  loginForm: FormGroup;
  loading: Boolean;

  constructor(
    private menuController: MenuController,
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService
  ) { }

  ionViewWillEnter() {
    this.menuController.enable(false);
  }

  ngOnInit() {
    this.loading = false;
    this.loginForm = this.fb.group({
      user: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  login() {
    this.loading = true;
    //setTimeout(() => this.router.navigate(['/home']), 1500)
    
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.get('user').value, this.loginForm.get('password').value).then(data => {
        
      })
    }
  }

}
