import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Reserva } from './../../model/Reserva'
import { LocalService } from './../../services/local.service'
import { ReservaService } from './../../services/reservas.service'

@Component({
    selector: 'app-reservas',
    templateUrl: './reservas.component.html',
    styleUrls: ['./reservas.component.scss'],
})
export class ReservasComponent implements OnInit {
    constructor(public reservaService: ReservaService, private activatedRoute: ActivatedRoute, private localService: LocalService, private router: Router) {}
    ngOnInit() {
        if (this.reservaService.misReservas?.length) {
            this.reservaService.init()
        }

        ;
    }

    public get admiteReservas(){
        return this.localService.local?.admiteReserva && environment.admiteReservas
    }
    public delete(r: Reserva) {
        this.reservaService.quitarReserva(r)
    }
    public verReserva(r) {
        this.reservaService.reservaEditada = r
        this.router.navigate(['nueva-reserva'])
    }

    public nuevaReserva() {
        this.reservaService.reservaEditada = null
        this.router.navigate(['/nueva-reserva'])
    }
    public ionViewDidEnter() {
        this.reservaService.actualizarEstados().then((e) => {})
    }
}
