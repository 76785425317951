import * as moment from 'moment';
import { Local } from './Local';
import { Ocupacion } from './Ocupacion';

export class TotalizadorOcupacion {

    private totales: Map<string, { personas: number, reservas: number }> = new Map();
    private _ocupacion: Ocupacion[] = [];
    public ocupados: Date[] = [];


    public get ocupacion(): Ocupacion[] {
        return this._ocupacion;
    }
    public set ocupacion(v: Ocupacion[]) {
        this._ocupacion = v;
        this.calcularTotales()
    }
    private calcularTotales() {
        this.totales = new Map();
        this.ocupacion.forEach(o => {
            var d = this.getKey(o.fecha, o.horario);
            if (!this.totales.has(d)) {
                this.totales.set(d, { personas: 0, reservas: 0 });
            }
            const t = this.totales.get(d);
            t.personas += o.cantidadPersonas;
            t.reservas += o.cantidadReservas;
        })
    }
    constructor(ocupacion: Ocupacion[], public fechaDesde: Date, public fechaHasta: Date, public local: Local) {
        this.ocupacion = ocupacion;
        this.fechaDesde = fechaDesde ? moment(fechaDesde).startOf('day').toDate() : null;
        this.fechaHasta = fechaHasta ? moment(fechaHasta).endOf('day').toDate() : null;
    }
    public aplicaFecha(date: Date) {
        return this.fechaDesde && moment(this.fechaDesde).isSameOrBefore(date) &&
            (!this.fechaHasta || moment(this.fechaHasta).isSameOrAfter(date));
    }

    public getPersonasByFecha(date: Date, horario: string) {
        const d = this.getKey(date, horario);
        const t = this.totales.get(d);
        return t ? t.personas : null;

    }

    private getKey(date: Date, horario: string) {
        return moment(date).format('DDMMYYY') + horario;
    }
    public getReservasByFecha(date: Date, horario: string) {
        const d = this.getKey(date, horario);
        const t = this.totales.get(d);
        return t ? t.reservas : null;
    }


}