export class Mensaje {
    public origen: string;
    public fecha: Date;
    public mensaje: string;

    public static fromData(data) {
        if (!data) return;

        let m = new Mensaje();
        m.origen = data.origen;
        m.fecha = new Date(data.fecha);
        m.mensaje = data.mensaje;
        return m;
    }
}