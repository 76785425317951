import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AlertController, Platform } from '@ionic/angular'
import { Storage } from '@ionic/storage'
import { BehaviorSubject, Subscription, throwError } from 'rxjs'
import { filter, first } from 'rxjs/operators'
import { AuthService } from 'src/app/services/auth.service'
import { MenuService } from 'src/app/services/menu.service'
import { ItemPedido } from '../model/ItemPedido'
import { Pedido } from '../model/Pedido'
import { environment } from './../../environments/environment'
import { EstadoDocumento } from './../model/EstadoDocumento'
import { Local } from './../model/Local'
import { Producto } from './../model/Producto'
import { LocalService } from './local.service'
import { IActualizable, NovedadService } from './novedades.service'
import { VibrationService } from './vibration.service'
const  PEDIDO_KEY = environment.tokenKey + '_' + 'pedido'
@Injectable({
    providedIn: 'root',
})
export class PedidoService implements IActualizable {
    constructor(
        private http: HttpClient,
        private storage: Storage,
        private alertController: AlertController,
        private novedadService: NovedadService,
        private localService: LocalService,
        private menuService: MenuService,
        private novedadesService: NovedadService,
        private vibrationService: VibrationService,
        private platform: Platform,
        private authService: AuthService
    ) {
        this.ready = new BehaviorSubject(false)
        // this.init();
        this.loadPedidoActual();
    }
    agregarProducto(item: ItemPedido) {
        if (!this.pedidoActual) {
            throwError('No hay pedido')
        }
        this.addToCart(Producto.fromItem(item))
    }

    public soloMenu() {
        return (
            !this.menuService?.menuSeleccionado?.esDisponible() ||
            !this.localService?.local?.admitePedido ||
            (this._pedidoActual?.menuId && this._pedidoActual?.menuId != this.menuService.menuSeleccionado?.id)
        )
    }
    public pedido: BehaviorSubject<Pedido> = new BehaviorSubject<Pedido>(null)
    public pedidoSeleccionadoObs: BehaviorSubject<Pedido> = new BehaviorSubject<Pedido>(null)
    public onItemsChange: BehaviorSubject<ItemPedido> = new BehaviorSubject(null)
    private _pedidoSeleccionado: Pedido
    private menuSub: Subscription;
    public get pedidoSeleccionado(): Pedido {
        return this._pedidoSeleccionado
    }
    public set pedidoSeleccionado(v: Pedido) {
        this._pedidoSeleccionado = v
        this.pedidoSeleccionadoObs.next(v)
    }

    public async updatePedido(p: Pedido) {
        this._pedidoActual = p
        // await this.storage.set(environment.tokenKey + "_" + "pedido", this._pedidoActual);
        this.storage.get(PEDIDO_KEY).then((ps: any) => {
            ps[this.pedidoActual.local.id] = this.pedidoActual
            this.storage.set(PEDIDO_KEY, ps)
        })
        this.pedido.next(this._pedidoActual)
    }



    public cleanAll() {
        this.storage.remove('pedidos')
        // this.storage.remove("pedido");
        if (this.pedidoActual?.local?.id) {
            this.storage.get(PEDIDO_KEY).then((ps: any) => {
                delete ps[this.pedidoActual.local.id]
                this.storage.set(PEDIDO_KEY, ps)
            })
        }

        this._pedidos = []
        this._pedidoActual = new Pedido()
    }
    private _pedidoActual: Pedido
    public get pedidoActual(): Pedido {
        return this._pedidoActual
    }
    public set pedidoActual(v: Pedido) {
        this._pedidoActual = v
        this.pedido.next(v)
    }

    private _pedidosPorMenu: any = {};

    public _pedidos: Pedido[] = []
    public counterUpdate
    public pedidos: BehaviorSubject<Pedido[]> = new BehaviorSubject([])
    private prevNovedad
    next = async (n) => {
        if (!this.prevNovedad || this.prevNovedad?.key != n?.key) {
            this.prevNovedad = n
            if (this._pedidos.some((p) => p.id == n.idEntidad)) {
                return this.getById(Number(n.idEntidad)).then((p) => {
                    this.notificarPedido(p)
                })
            }
        }
        return Promise.resolve(true)
    }
    destroy = () => {
        clearInterval(this.counterUpdate)
        this.platformSub = null
    }
    private async loadPedidos() {
        if (this.authService.hayLogueado() && !this.authService.esUserDefault()) {
            return this.buscarPedidos()
        } else {
            let v = await this.storage.get(environment.tokenKey + '_' + 'pedidos')
            if (v) {
                this._pedidos = v.filter((p) => p != undefined).map((p) => Pedido.fromData(p))
            } else {
                this._pedidos = []
            }

            this.pedidos.next(this._pedidos)
            this.setCrone()
            return Promise.resolve(this._pedidos)
        }
    }
    private async loadPedidoActual() {
       return this.storage.get(PEDIDO_KEY).then((ps: any) => {
            this._pedidosPorMenu = ps || {};
            if (this.pedidoActual?.local?.id) {
                this._pedidosPorMenu[this.pedidoActual.local.id] = this.pedidoActual
                this.storage.set(PEDIDO_KEY, this._pedidosPorMenu)
            }
            if(!this.menuSub)
                this.menuSub = this.menuService.menuSeleccioandoObs.pipe(filter(l=>l!=undefined)).subscribe(l=>{
                    this._pedidoActual = Pedido.fromData(this._pedidosPorMenu[l.idLocal])
                    if (!this.pedidoActual) {
                        this._pedidoActual = new Pedido()
                    }
                    this.pedido.next(this._pedidoActual);
                })
            return Promise.resolve(this.pedidoActual)
        })
   
    }

    public ready: BehaviorSubject<boolean>
    private platformSub: Subscription
    
    public init() {
        const promises: Promise<any>[] = [
            this.loadPedidos(),
            // this.loadPedidoActual(),
            this.suscribeNovedades(),
            this.suscribeToPlatform(),
        ]

        Promise.all(promises).finally(() => {
            this.ready.next(true)
            // this.ready.complete();
        })

        this.localService.localSeleccionado.pipe(filter(l => l != null)).subscribe(() => this.loadPedidoActual())
    }
    private suscribeToPlatform(): Promise<any> {
        return this.platform.ready().then((r) => {
            if (!this.platformSub)
                this.platformSub = this.platform.resume.subscribe((r) => {
                    this.actualizarEstados()
                })
            return true
        })
    }
    private notificando
    public notificarListos() {
        if (!this.notificando) {
            this.notificando = Promise.resolve(() => {
                this._pedidos.filter((p) => p.estado.codigo == 'EN').forEach((p) => this.notificarPedido(p))
            }).finally((this.notificando = null))
        }
        return this.notificando
    }
    private count: number = 0
    private vibrate() {
        this.vibrationService.vibrate()
    }

    private notificarPedido(p: Pedido) {
        if (p) {
            if (p.estado?.codigo == 'EN') {
                this.vibrate()
                this.alert('Su pedido N° ' + p.numeroPedido + ' está listo para retirar')
            } else if (p.estado?.codigo == 'A' || p.estado?.codigo == 'EP') {
                this.alert('Su pedido N° ' + p.numeroPedido + ' está en preparación')
            } else if (p.estado?.codigo == 'R' || p.estado?.codigo == 'C') {
                this.alert('Su pedido N° ' + p.numeroPedido + ' fue cancelado')
            }
        }
    }
    private alertScreen
    async alert(m: string) {
        if (!this.alertScreen) {
            const t = await this.alertController.create({
                message: m,
                buttons: [
                    {
                        text: 'Ok',
                        role: 'ok',
                        cssClass: 'secondary',
                        handler: (v) => {
                            this.vibrationService.stopVibration()
                        },
                    },
                ],
            })
            t.onDidDismiss().then((r) => {
                this.vibrationService.stopVibration()
                this.alertScreen = null
            })
            t.present()
            this.alertScreen = t
        }
    }
    setCrone = () => {
        if (this.platform.is('ios') || this.platform.is('ipad') || this.platform.is('iphone')) {
            if (
                !this.counterUpdate &&
                this._pedidos.length > 0 &&
                this._pedidos.some((p) => !p.estado || p.estado?.codigo == 'EN' || p.estado?.codigo == 'EP' || p.estado?.codigo == 'A')
            ) {
                this.counterUpdate = setInterval(() => {
                    this.actualizarEstados()
                        .toPromise()
                        .then((e) => {
                            this.notificarListos()
                        })
                }, 20000)
            }
        }
    }
    suscribeNovedades = () => {
        this.pedidos.subscribe((pe) => {
            pe.forEach((p) => {
                this.novedadService.registrarPushObserver('pedido', this, p.id)
            })
        })
        return Promise.resolve(true)
    }
    getById(idEntidad: number): Promise<Pedido> {
        const p = this._pedidos.filter((pp) => pp.id == idEntidad)[0]
        if (!p) return Promise.resolve(null)
        return this.http
            .get(`${this.getApiURL()}api/pedido/${idEntidad}/${p.esPager}`)
            .toPromise()
            .then((p) => {
                const ped = Pedido.fromData(p)
                this._pedidos[this._pedidos.findIndex((a) => a.id == ped.id)] = ped
                this.pedidos.next(this._pedidos)
                return ped
            })
            .catch((e) => {
                return Promise.reject()
            })
    }
    getApiURL() {
        return environment.apiUrl
    }
    clearPedido() {
        this.storage.get(PEDIDO_KEY).then((ps: any) => {
            delete ps[this.pedidoActual.local.id]
            this.storage.set(PEDIDO_KEY, ps)
        })
        this.pedidoActual = new Pedido()
        // this.storage.remove("pedido");
    }
    addToCart(producto: Producto) {
        if (!this._pedidoActual) this._pedidoActual = new Pedido()
        if (!this.pedidoActual.local) this._pedidoActual.local = this.localService.local
        if (!this.pedidoActual.menuId) this._pedidoActual.menuId = this.menuService.menuSeleccionado.id

        this._pedidoActual.agregarProducto(producto)
        producto.recomendados
            ?.filter((p) => p.cantidad > 0)
            .forEach((p) => {
                this._pedidoActual.agregarItemPedido(p)
            })
        this.pedido.next(this._pedidoActual)
        this.onItemsChange.next(this._pedidoActual.getByProducto(producto))
        this.storeOrder()
    }

    removerFromCart(producto: ItemPedido) {
        this._pedidoActual.itemsPedido = this._pedidoActual.itemsPedido.filter((i) => i != producto)
        // if (this._pedidoActual.itemsPedido.length == 0) {
        //     this._pedidoActual.menuId = null
        //     this._pedidoActual.local = null
        // }
        producto.cantidad = 0
        this.pedido.next(this._pedidoActual)
        this.onItemsChange.next(producto)
        this.storeOrder()
    }
    public guardarPedido(p: Pedido) {
        if (p) {
            let i = this._pedidos.findIndex((pp) => pp?.id == p.id && pp?.local?.id == p?.local?.id)
            if (i < 0) {
                this._pedidos.push(p)
            } else { 
                this._pedidos[i] = p
            }
            
            this.pedidos.next(this._pedidos)
            this.storage.set(environment.tokenKey + '_' + 'pedidos', this._pedidos)
        }
    }
    async registrarPedido(pedido?: Pedido): Promise<Pedido> {
        let pedidoNuevo = pedido || this._pedidoActual

        pedidoNuevo.pushToken = await this.novedadService.getPushToken()
        return this.http
            .post(`${this.getApiURL()}api/pedido/registrarPedido`, pedidoNuevo)
            .toPromise()
            .then((r: any) => {
                const p = Pedido.fromData(r)
                this.guardarPedido(p)
                return p
            })
    }

    public resetPedido(p: Pedido) {
        let nuevo = new Pedido();
        nuevo.local = p.local;
        nuevo.menuId = p.menuId;
        nuevo.tipoEntrega = p.tipoEntrega;
        if (p.mesaId) {
            nuevo.mesaId = p.mesaId;
        }
        this.updatePedido(nuevo)
    }
    private _performUpdate() {
        return this.http
            .post(`${this.getApiURL()}api/pedido/updateEstados`, {
                idPedidos: this._pedidos.filter((p) => !p.esPager && p.noFinalizado()).map((p) => p.id),
                idPagers: this._pedidos.filter((p) => p.esPager && p.noFinalizado()).map((p) => p.id),
            })
            .toPromise()
            .then((r: any) => {
                r.forEach((p) => {
                    var o = this._pedidos.filter((pp) => p.id == pp.id)[0]
                    if (o) {
                        o.estado = EstadoDocumento.fromData(p.estado)
                        o.local = Local.fromData(p.local)
                    }
                })
                this.storage.set(environment.tokenKey + '_' + 'pedidos', this._pedidos)
                this.pedidos.next(this._pedidos)
                this.notificarListos()
                return true
            })
    }
    actualizarEstados() {
        const obs = this.ready.pipe(
            filter((v) => v == true),
            first()
        )
        obs.subscribe((r) => {
            return this._performUpdate()
        })
        return obs
    }

    async asociarPedido(numeroPedido: string, codigoVerificacion: string, esPager: boolean, idLocal?: number): Promise<Pedido> {
        let pushToken = await this.novedadesService.getPushToken()
        if (!pushToken) {
            this.alert('No se encuentran habilitadas las notificaciones')
            return Promise.resolve(null)
        }
        return this.http
            .post(`${this.getApiURL()}api/pedido/asociar`, {
                numeroPedido: numeroPedido,
                codigoVerificacion: codigoVerificacion,
                idLocal: idLocal,
                esPager: esPager,
                pushToken: pushToken,
            })
            .toPromise()
            .then((r: any) => {
                this.setCrone()
                return Pedido.fromData(r)
            })
    }
    agregarPedido(p: Pedido) {
        if (!p) return
        if (!this._pedidos.some((pp) => pp?.id == p.id)) {
            this._pedidos.push(p)
            this.storage.set(environment.tokenKey + '_' + 'pedidos', this._pedidos)
        }
        this.setCrone()
        //this._performUpdate();
    }
    storeOrder() {
        this.storage.get(PEDIDO_KEY).then((ps: any) => {
            let aActualizar = []
            
            if (ps) {
                aActualizar = ps
            }

            aActualizar[this.pedidoActual.local.id] = this.pedidoActual
            this.storage.set(PEDIDO_KEY, aActualizar)
        })
    }

    buscarPedidos() {
        return this.http
            .get(`${this.getApiURL()}api/pedido/getPedidos/`)
            .toPromise()
            .then((p: Pedido[]) => {
                this._pedidos = p.map((pedido) => Pedido.fromData(pedido))
                this.pedidos.next(this._pedidos)
            })
            .catch((e) => {
                return Promise.reject()
            })
    }

    async registrarCobroMP(pedido: Pedido): Promise<Pedido> {
        return this.http
            .post(`${this.getApiURL()}api/pedido/registrarCobroMP`, pedido)
            .toPromise()
            .then((r: any) => {
                this.pedidoActual = Pedido.fromData(r)
                return this._pedidoActual
            })
    }
}
