import { LocalService } from './local.service';
import { BehaviorSubject } from 'rxjs';
import { Distancias } from './../model/Distancias';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class LocalizacionService extends BaseService {

  public baseName(): string {
    return "localizacion"
  }

  public costoObs: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(http: HttpClient) {
    super(http);
  }

  public actualizarCosto(localId: number, placeId: string) {
    const geocoder = new google.maps.Geocoder();
    let distancias = new Distancias();

    geocoder.geocode({ placeId: placeId }, (results, status) => {
      if (status == 'OK') {
        distancias.local = localId;
        distancias.lat = results[0].geometry.location.lat();
        distancias.lng  = results[0].geometry.location.lng();
        this.http.post(`${this.getApiURL()}costo-delivery/costo-distancia`,distancias).toPromise().then((c:any) => {
          this.costoObs.next(c);
        });
      } else this.handleError(status);

    })
  }
}
