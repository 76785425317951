import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuController, ToastController } from '@ionic/angular';
import { combineLatest, Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { Categoria } from 'src/app/model/Categoria';
import { PedidoService } from 'src/app/services/pedido.service';
import { MenuDelDia } from './../../model/MenuDelDia';
import { LocalService } from './../../services/local.service';
import { MenuService } from './../../services/menu.service';
import { NavigationService } from './../../services/navigation.service';



@Component({
	selector: 'app-home',
	templateUrl: './home.page.html',
	styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit, OnDestroy {
	loading: boolean;
	public conLanding = false;
	menu: MenuDelDia;
	data: any[] = [];
	slideOpts = {
		initialSlide: 0,
		speed: 2600,
		/*autoplay:
		{
			delay: 15000
		}*/

	};
	public mensajeVisible = false;
	public searching = false;
	public puedeCambiar = true;
	private menuChangeSub: Subscription;
	constructor(
		private menuController: MenuController,
		private router: Router, private toastController: ToastController,
		private pedidoService: PedidoService,
		private menuService: MenuService,
		private localService: LocalService,
		private navigationService: NavigationService,
		private activatedRoute: ActivatedRoute
	) { }

	ionViewWillEnter() {
		this.menuController.enable(true);
	}
	public get local(){
		return this.localService?.local || {imagenUrl: 'assets/logo.jpg'}
	}
	ngOnInit() {
		this.loading = true;
		this.searching = true;

		combineLatest([this.activatedRoute.queryParams, this.activatedRoute.params])
		.pipe(
			map((v) => {
				return { queryParams: v[0], params: v[1] }
			})
		)
		.subscribe(async (params) => {
			const idMenu = params.queryParams.idMenu
			const idLocal = params.queryParams.idLocal
			this.loading = true;
			this.searching = true;
			if (idMenu && idLocal && this.menuService.menuSeleccionado?.id == idMenu){
				this.updateMenu(this.menuService.menuSeleccionado);
			}else if (idLocal &&  this.localService.local?.id != idLocal) {
				await this.localService.seleccionarLocal(idLocal)
				if (idMenu && this.menuService.menuSeleccionado?.id != idMenu) {
					await this.menuService.seleccionarMenu(idMenu, idLocal)
				} else {
					this.toast("No se encontró el menú buscado");
					this.loading = false;
					this.searching = false;
					return;
				}
			} else {
				if (!this.menuService.menuSeleccionado || !this.localService.local){
					this.toast("No se encontró el menú buscado");
					this.loading = false;
					this.searching = false;
					return;
				};
			}
			this.loading = false;
			this.searching = false;
		})
		if (!this.menuChangeSub) {
		this.menuChangeSub = this.menuService.menuSeleccioandoObs.subscribe(m => {
			//this.menu = null;
			if (m && !this.menu?.key.equals(m.key)) {
				this.updateMenu(m)
			}
			this.loading = false;
			this.searching = false;

		})
		}

	}
	async toast(m: string) {
		const t = await this.toastController.create({
		  message: m,
		  duration: 3000,
		  position: "middle"
		});
		t.present();
	  }
	public cambiarLocal() {
		this.localService.local = null;
		this.navigationService.goToLanding();
	}
	public navigateMenu() {
		this.navigationService.goToMenu();
	}
	public ionViewDidEnter() {
		/*Promise.all([this.menuService.ready.toPromise(), this.localService.ready.toPromise()]).then(v => {
			if (this.menu && !this.menu.tieneLanding) {
				this.navigationService.goToMenu();

			} else if (!this.searching && (!this.menuService.menuSeleccionado || !this.localService.local)) {
				this.navigationService.goToLanding();
			}
		});*/

	}
	goToMenu(cat: Categoria) {
		this.router.navigate(['/menu/' + this.localService.local.id + "/" + this.menu.id], { queryParams: { idCategoria: cat.id } });
	}

	viewOrder(): void {
		this.pedidoService.pedidoSeleccionado = this.pedidoService.pedidoActual;
		this.router.navigate(['/pedido'])
	}
	public isMobile() {
		return window.innerWidth <= 640;
	}
	updateMenu = (m:MenuDelDia)=>{
		this.menu = m;
		this.menuService.getCategories(m.key).pipe(filter(r => r != undefined), first()).subscribe(r => {
			this.menu = r;
			if (!this.menu.tieneLanding) {
				this.navigationService.goToMenu();
			} else {
				this.conLanding = true;
			}

	}, e => {
		this.toast("No se pudo cargar el menú. intenta refrescando la aplicación.")
	})
	}
	goToLanding() {
		this.navigationService.goToLanding()
	}

	ngOnDestroy(): void {
		if (this.menuChangeSub) { this.menuChangeSub.unsubscribe(); }
	}

}
