import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { ProductoService } from 'src/app/services/producto.service'
import { environment } from 'src/environments/environment'
import { MenuDelDia } from '../model/MenuDelDia'
import { Novedad } from '../model/Novedad'
import { Promocion } from '../model/Promocion'
import { Local } from './../model/Local'
import { Menu, MenuKey } from './../model/Menu'
import { LanguageService } from './language.service'
import { IActualizable, NovedadService } from './novedades.service'

@Injectable({
    providedIn: 'root',
})
export class MenuService implements IActualizable {
    private _menus: Map<number, Map<number, Menu>>
    public get menus(): Map<number, Map<number, Menu>> {
        return this._menus
    }
    public set menus(v: Map<number, Map<number, Menu>>) {
        this._menus = v
    }

    public ready: BehaviorSubject<boolean> = new BehaviorSubject(false)

    private menuDia: MenuDelDia[] = []
    public menusChange: BehaviorSubject<MenuDelDia[]> = new BehaviorSubject([])
    private _menuSeleccionado: MenuDelDia
    public menuSeleccioandoObs: BehaviorSubject<MenuDelDia> = new BehaviorSubject(null)
    public get menuSeleccionado(): MenuDelDia {
        return this._menuSeleccionado
    }
    public set menuSeleccionado(v: MenuDelDia) {
        const prev = this._menuSeleccionado?.id
        this._menuSeleccionado = v
        if (prev != v?.id) {
            this.menuSeleccioandoObs.next(v)
        }
    }
    private catSuscribers: Map<string, Observable<MenuDelDia>> = new Map()
    constructor(
        private http: HttpClient,
        private novedadService: NovedadService,
        private languageService: LanguageService,
        private productoService: ProductoService
    ) {
        this.init()
    }
    private lastN: Novedad
    next(n: Novedad) {
        if (this.lastN?.key != n.key) {
            if (n.tipo == 'promocion' && this._menuSeleccionado) {
                this.menuDia = []
                const key = new MenuKey(this._menuSeleccionado.id, this._menuSeleccionado.idLocal)
                this.menuSeleccionado = null
                const p = this.seleccionarMenu(key.idMenu, key.idLocal)
                this.lastN = n
                return p
            } else if (n.tipo == 'menu') {
                if (n.idEntidad && n.idLocal) {
                    const key = new MenuKey(n.idEntidad, n.idLocal)

                    return this.updateMenu(key).then((d) => {
                        const m = this.menuDia.filter((menu) => menu.key && menu.key.equals(key))[0]
                        if (m?.id) {
                            if (this.menuSeleccionado?.key.equals(m.key)) {
                                this.menuSeleccionado = m
                            }
                        }
                    })
                }
            }
        }
        return Promise.resolve(true)
    }
    destroy() {}
    cleanCache() {
        this.menuDia = []
        this.menus = new Map()
        this.menuSeleccionado = null
        // this.storage.remove("menu");
    }
    private init() {
        this.novedadService.check()

        this.ready.next(true)
        this.ready.complete()

        this.novedadService.registrarObservador('menu', this)
        this.novedadService.registrarObservador('promocion', this)
    }
    public seleccionarMenu(idMenu: number, idLocal: number) {
            let m = this.menuDia.filter((menu) => menu.key.equals(new MenuKey(idMenu, idLocal)))[0]

            if (m?.id) {
                this.menuSeleccionado = m;
                return Promise.resolve(this.menuSeleccionado)
            } else {
                return this.updateMenu(new MenuKey(idMenu, idLocal)).then((m) => {
                    let mNuevo = this.menuDia.filter((menu) => menu.key.equals(new MenuKey(idMenu, idLocal)))[0]
                    if (mNuevo?.id) {
                        this.menuSeleccionado = mNuevo
                    }else {
                        this.menuSeleccionado = null;
                    }
                    return Promise.resolve(this.menuSeleccionado)
                })
            }
    }
    public getMenuSeleccionado(): Promise<MenuDelDia> {
        return this.menuSeleccionado ? Promise.resolve(this.menuSeleccionado) : this.menuSeleccioandoObs.pipe(filter((m) => m != undefined)).toPromise()
    }
    private updateMenu(key: MenuKey): Promise<MenuDelDia[]> {
        return this.http
            .get(
                `${this.getApiURL()}api/menu-del-dia/getMenuDelDia/?idMenu=${key.idMenu}&idLocal=${key.idLocal}&idioma=${this.languageService.selectedLanguage}`
            )
            .pipe(map((m) => MenuDelDia.fromData(m)))
            .toPromise()
            .then((m) => {
                if (m) {
                    const i = this.menuDia.findIndex((mm) => mm.key.equals(m.key))
                    if (i >= 0) {
                        this.menuDia[this.menuDia.findIndex((mm) => mm.key.equals(m.key))] = m
                    } else {
                        this.menuDia.push(m)
                    }
                    this.productoService.actualizarCacheDesdeMenu(m)
                    this.menusChange.next(this.menuDia)
                    this.menuDia.forEach((menu) => {
                        menu.categorias = menu.categorias.sort((a,b)=>{return a.peso > b.peso ? -1 :1})
                        menu.categorias.forEach((categoria) => {
                            if (categoria?.items.length) {
                                categoria.items.sort((a, b) => a.peso - b.peso)
                            }
                        })
                    })

                  
                }else {
                  this.menuSeleccionado = null;
                  this.menuSeleccioandoObs.next(null);
                  this.menuDia = [];
                  this.menusChange.next(this.menuDia);
                  
                }
                return this.menuDia
            })
    }
    getCategories(key: MenuKey): Observable<MenuDelDia> {
        this.novedadService.check()
        if (!this.menuDia.some((m) => m.key.equals(key))) {
            this.updateMenu(key).then((d) => {
                return this.getCategories(key)
            })
        }
        if (!this.catSuscribers.has(key.str)) {
            this.catSuscribers.set(
                key.str,
                new Observable<MenuDelDia>((obs) => {
                    this.menusChange.subscribe((d) => {
                        const m = this.menuDia? this.menuDia.filter((menu) => menu.key.equals(key))[0]: null;
                        if (m?.id) {
                            obs.next(m)
                        }
                    })
                })
            )
        }
        return this.catSuscribers.get(key.str)
    }

    private promocionesSearch: Promise<Promocion[]>
    getPromociones(): Promise<Promocion[]> {
        if (this.promocionesSearch) return this.promocionesSearch
        this.promocionesSearch = this.http
            .get(`${this.getApiURL()}api/menu-del-dia/promociones`)
            .toPromise()
            .then((r: any) => {
                this.promocionesSearch = null
                return r.map((p) => Promocion.fromData(p))
            })
        return this.promocionesSearch
    }
    private localSearch: Promise<Menu[]>
    getMenus(local?: Local): Promise<Menu[]> {
        if (this.menus && this.menus.has(local.id) && this.menus.get(local.id).size > 0) {
            return Promise.resolve(Array.from(this.menus.get(local.id).values()).map((m) => Menu.fromData(m)))
        } else {
            if (this.localSearch) {
                return this.localSearch
            }
        }
        this.localSearch = this.http
            .get(`${this.getApiURL()}api/menu-del-dia/menusDisponibles${local ? '?idLocal=' + local.id : ''}`)
            .toPromise()
            .then((r: any) => {
                if (!this.menus) this.menus = new Map()
                this.menus.set(
                    local?.id,
                    r.map((p) => Menu.fromData(p))
                )
                // this.storage.set(environment.tokenKey + "_" + "menusCabecera", this.menus);
                return Array.from(this.menus.get(local.id).values())
            })
            .finally(() => {
                this.localSearch = null
            })
        return this.localSearch
    }

    getApiURL() {
        return environment.apiUrl
    }
}
