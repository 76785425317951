// import { LoginService } from './../../services/login.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Register } from 'src/app/model/Register';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {

  public register: Register;


  constructor(public login: AuthService,
    private router: Router,
    public alertController: AlertController,
  ) {
  }

  ngOnInit() {
    this.register = this.login.register;

  }

  registrar() {
    this.login.registrar().then(r => {
      this.mostrarMensaje();
      this.router.navigate(["/landing"])
    });
  }

  async mostrarMensaje() {
    const alert = await this.alertController.create({
      header: '',
      message: 'Usuario creado exitosamente',
      // buttons: ['Ok'],
    });

    await alert.present();
  }

  test() {
    this.login.refreshToken();
  }
}
