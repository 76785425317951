import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Mayor18Service } from './../mayor18.service';

@Component({
  selector: 'app-not-allowed',
  templateUrl: './not-allowed.component.html',
  styleUrls: ['./not-allowed.component.scss'],
})
export class NotAllowedComponent implements OnInit {

  constructor(public mayor18Service: Mayor18Service, private router: Router) { }
  public mayor18() {
    this.mayor18Service.mayor18 = true;
    this.router.navigate(['landing']);
  }
  ngOnInit() { }

}
