import { Identificable } from './../common/model/Identficable';

export class Descriptivo implements Identificable {

    constructor(public codigo?: string, public descripcion: string = "", public id?: number) {
    }


    public static fromData(a: any): Descriptivo {
        return a ? new Descriptivo(a.codigo, a.descripcion, a.id) : null;
    }

    get label(): string {
        return this.descripcion;
    }

    get value(): any {
        return this;
    }

    public static getCodigo(o: any, field: string = "codigo", ifnot: string = ""): string {
        return o ? o[field] : ifnot;
    }

    public toString() {
        return this.descripcion;
    }


}