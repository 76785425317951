import { Component, Input, OnInit } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { IShareable } from './../../model/IShareable';
const { Share } = Plugins;
@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {

  constructor() { }
  @Input()
  public shareable: IShareable;
  ngOnInit() { }

  async shareWhatsapp() {
    await Share.share({
      title: this.shareable.title,
      text: this.shareable.text,
      url: this.shareable.link
    })
  }
}
