export class Filtro {
    constructor(
        public name: string,
        public filterMap,
        page: number = 0,
        size: number = 40,
        sortField: string = 'id',
        sortOrder: number = 1,
        guardable: boolean = true
    ) { }
    
    public replacer(key: string, value) {
        if (
            key == 'stateObs' ||
            key == 'storageService' ||
            key == 'isReady' ||
            key == 'onCleanCallback' ||
            key == 'ready' ||
            key == '_currValue' ||
            key == 'dataChange' ||
            key == 'valueChange' ||
            key == 'saveValue' ||
            key == 'sub' ||
            key == 'summary' ||
            key == 'filterMap' ||
            key == 'name' ||
            key == 'count'
        )
            return undefined
        else return value
    }
    
    public get json(): any {
        return JSON.parse(JSON.stringify(this, this.replacer).replace(/_/g, ''))
    }
}