import { Descriptivo } from './Descriptivo'

export class ConfiguracionMenu {
    constructor(public tipoLayout: Descriptivo) {}

    public static fromData(data: any): ConfiguracionMenu {
        if (!data) return null
        const o: ConfiguracionMenu = new ConfiguracionMenu(Descriptivo.fromData(data.tipoLayout))

        return o
    }
}
