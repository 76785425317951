import { Component, Input, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { Restriccion } from 'src/app/model/Restriccion'

@Component({
    selector: 'app-modal-filtro',
    templateUrl: './modal-filtro.component.html',
    styleUrls: ['./modal-filtro.component.scss'],
})
export class ModalFiltroComponent implements OnInit {
    private _restricciones: Restriccion[]
    public get restricciones(): Restriccion[] {
        return this._restricciones
    }

    @Input()
    public set restricciones(v: Restriccion[]) {
        this.loading = false;
        this._restricciones = v
        this.restriccionesExcluyentes = this._restricciones.filter(r => r.excluyente) ?? [];
        this.restriccionesIncluyentes = this._restricciones.filter(r => !r.excluyente) ?? [];
    }

    restriccionesExcluyentes: Restriccion[] = []
    restriccionesIncluyentes: Restriccion[] = []
    seccion: string = 'incluyentes';
    public loading : boolean = true;
    private _searchFC: string
    public get searchFC(): string {
        return this._searchFC
    }
    @Input()
    public set searchFC(v: string) {
        this._searchFC = v
    }

    constructor(private modalController: ModalController) {}

    ngOnInit() {}

    aplicarFiltros() {
        let filtros = {
            searchFC: this.searchFC,
            restricciones: [...this.restriccionesIncluyentes, ...this.restriccionesExcluyentes],
            filtroModificado: this.restriccionesExcluyentes.some(r=> r.seleccionada) || this.restriccionesIncluyentes.some(r=> !r.seleccionada)
        }
        this.dismiss(filtros)
    }

    dismiss(f?: any) {
        this.modalController.dismiss(f)
    }

    isMobile() {
        return window.innerWidth <= 640
    }
}
