import { ConfiguracionMenu } from './ConfiguracionMenu'
import { Categoria } from './Categoria'
import { Disponibilidad } from './Disponibilidad'
import { MenuKey } from './Menu'
import { ItemPedido } from './ItemPedido'
import { ProfilePic } from './ProfilePic'
export class MenuDelDia {
    public key: MenuKey
    constructor(
        public id?: number,
        public descripcion?: string,
        public detalle?: string,
        public categorias: Categoria[] = [],
        public tieneLanding: boolean = true,
        public disponibilidad: Disponibilidad = new Disponibilidad(),
        public idLocal?: number,
        public configuracion?: ConfiguracionMenu,
        public mostrarMasVendidos = false,
        public imagenPortada?: string,
        
    ) {
        this.key = new MenuKey(this.id, this.idLocal)
    }

    public static fromData(data) {
        if (!data) return
        let o: MenuDelDia = new MenuDelDia(
            data.id,
            data.descripcion,
            data.detalle,
            data.categorias ? data.categorias.map((c) => Categoria.fromData(c)) : [],
            data.tieneLanding,
            Disponibilidad.fromData(data.disponibilidad),
            data.idLocal,
            ConfiguracionMenu.fromData(data.configuracion),
            data.mostrarMasVendidos,
            data.imagenPortada
        )

        o.categorias = o.categorias.filter((c) => c.items.some((i) => i.visible))
        return o
    }
    public esDisponible() {
        return this.disponibilidad ? this.disponibilidad.esDisponible(new Date()) : false
    }

    public getPromociones() {
        let jsonEnPromocion = {}
        let enPromocion: ItemPedido[] = []
        this.categorias.forEach((c) => {
            c.items.forEach((item) => {
                if (item.precioDescuento) {
                    jsonEnPromocion[item.id] = item
                } else if (item.nuevo) {
                    jsonEnPromocion[item.id] = item
                }
            })
        })
        for (let val in jsonEnPromocion) {
            enPromocion.push(jsonEnPromocion[val])
        }
        return enPromocion
    }

    public get bannerUrl() {
        return this.imagenPortada ? this.imagenPortada : 'assets/img/default_image.png'
    }
}
