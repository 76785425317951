import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { PedidoService } from 'src/app/services/pedido.service';
import { ProductoService } from 'src/app/services/producto.service';
import { DEFAULT_IMAGE } from 'src/assets/data/constants';
import { IShareable } from './../../model/IShareable';
import { Local } from './../../model/Local';
import { Producto } from './../../model/Producto';
import { LocalService } from './../../services/local.service';
import { MenuService } from './../../services/menu.service';
import { NovedadService } from './../../services/novedades.service';


@Component({
    selector: 'app-producto',
    templateUrl: './producto.page.html',
    styleUrls: ['./producto.page.scss'],
    animations: [
        trigger('flyLeft', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateX(-30vw)' }),
                animate('400ms ease-in-out', style({ opacity: 1, transform: 'translateX(1vw)' })),
                animate('200ms ease-out', style({ opacity: 1, transform: 'translateX(0)' })),
            ]),
            transition(':leave', [
                animate('150ms ease-out', style({ opacity: 1, transform: 'translateX(1vw)' })),
                animate('200ms', style({ opacity: 0, transform: 'translateX(-100vw)' })),
            ]),
        ]),
        trigger('flyRight', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateX(60vw)' }),
                animate('400ms ease-in-out', style({ opacity: 1, transform: 'translateX(-1vw)' })),
                animate('200ms ease-out', style({ opacity: 1, transform: 'translateX(0)' })),
            ]),
            transition(':leave', [
                animate('150ms ease-out', style({ opacity: 1, transform: 'translateX(-1vw)' })),
                animate('200ms', style({ opacity: 0, transform: 'translateX(100vw)' })),
            ]),
        ]),
        trigger('flyBottom', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(30vw)' }),
                animate('400ms ease-in-out', style({ opacity: 1, transform: 'translateY(-2vh)' })),
                animate('200ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
            ]),
            transition(':leave', [
                style({ opacity: 1, transform: 'translateY(-1vh)' }),
                animate('400ms ease-out', style({ opacity: 0, transform: 'translateY(10vw)' })),
            ]),
        ]),
    ],
})
export class ProductoPage implements OnInit, OnDestroy {
    public mostrarTotal: boolean = true
    public favorito: boolean = true
    ingredientes: any
    loading: boolean
    emptyCart: boolean
    public toShare: IShareable
    viewRendered: boolean = false
    cantidadEnCarrito: number
    public local: Local;
    public get pedido() {
        return this.pedidoService.pedidoActual
    }

    private _producto: Producto
    public get producto(): Producto {
        return this._producto
    }
    public set producto(v: Producto) {
        this._producto = v
        if (v) {
            this.toShare = {
                text: v.descripcionPreview ? v.descripcionPreview : v.descripcion,
                title: v.descripcion,
                link:
                    window.location.origin +
                    '#/producto/' +
                    v.id +
                    '/' +
                    this.menuService.menuSeleccionado.id +
                    '/' +
                    this.localService.local.id,
            }
        }
        if (this.pedido && this.producto) {
            const itemPedido = this.pedido.getByProducto(this.producto)
            if (itemPedido) {
            
                this.producto.cantidad = itemPedido.cantidad
                this.cantidadEnCarrito = itemPedido.cantidad
                
            } else {
                this.producto.cantidad = 1;
            }
        }
    }
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public productoService: ProductoService,
        private menuService: MenuService,
        private pedidoService: PedidoService,
        private novedadesService: NovedadService,
        private localService: LocalService,
        private modalController: ModalController
    ) {}
        public get defaultImage(){
            return this.localService.local?.imagen?.picPath || DEFAULT_IMAGE;
        }
    public fillProducto(idMenu, idProducto, idLocal) {
        this.loading = true
        
        this.productoService.getProductoById(idProducto, idMenu, idLocal).subscribe((data: Producto) => {
            if (this.producto?.id != data?.id) {
                if (this.producto.precioDescuento != data.precioDescuento) this.novedadesService.check()
                this.producto = data

                if (this.producto && !this.producto?.cantidad) this.producto.cantidad = 1
            }
            if (data) this.loading = false
           
        })
    }
    ionViewDidEnter() {
        this.viewRendered = true
    }
    public get soloMenu() {
        return this.pedidoService.soloMenu()
    }
    public isMobile() {
        return window.innerWidth <= 640
    }
    public sub: Subscription
    ngOnInit() {
        this.sub = this.productoService.productoObs.subscribe((p) => {
            this.producto = p
            this.loading = false
        })
        this.producto = this.productoService.productoSeleccionado;
        
        this.activatedRoute.params.subscribe((params) => {
            if (params.idMenu && params.idLocal && !this.menuService.menuSeleccionado) {
                this.menuService.seleccionarMenu(params.idMenu, params.idLocal)
            }
            if (params.idLocal && !this.localService.localSeleccionado) {
                this.localService.seleccionarLocal(params.idLocal)
            }
            if (params.idMenu && params.id && params.idLocal) {
                this.fillProducto(params.idMenu, params.id, params.idLocal)
            }
        })

    }
    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe()
        this.producto = null
    }
    addToCart(producto: Producto) {
        this.pedidoService.addToCart(producto)
        // this.cantidadEnCarrito = producto.cantidad
        // this.productoService.productoSeleccionado = null
         this.modalController.dismiss()
    }

    viewOrder() {
        this.router.navigate(['/pedido'])
    }

    textoBotonAgregar(){
       if(!this.cantidadEnCarrito){
            return "Agregar"
        }else{
            if(this.cantidadEnCarrito == this.producto.cantidad){
                return "Aceptar"
            }else{
                return "Actualizar"
            } 
        }
    }
}
