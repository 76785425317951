import { Descriptivo } from './Descriptivo';
export class GastoPedido {
    id?: number;
	tipo?: Descriptivo;
    descripcion?: string;
    pedido?: Descriptivo;
    importe?: number;

	constructor(descripcion?: string, importe?: number, tipo?: Descriptivo) {
		this.descripcion = descripcion;
		this.importe = importe;
		this.tipo = tipo;
	}

    public static fromData(data) {
		if (!data) return;
		const o = new GastoPedido();
		Object.assign(o, data);
		o.pedido = Descriptivo.fromData(data.pedido);
		o.tipo = Descriptivo.fromData(data.tipo);
		return o;
	}
}