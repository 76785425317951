import { Descriptivo } from './Descriptivo';
import { EstadoDocumento } from './EstadoDocumento';
import { GastoPedido } from './GastoPedido';
import { ItemPedido } from './ItemPedido';
import { Local } from './Local';
import { Producto } from './Producto';
import { ProductoCabecera } from './ProductoCabecera';
import { Ubicacion } from './Ubicacion';

export class Pedido {
   

	constructor(
		public id?: number,
		public numeroPedido?: string,
		public fecha?: Date,
		public estado?: EstadoDocumento,
		public total: number = 0,
		public metodoPago: Descriptivo = new Descriptivo('E', "Efectivo"),
		public itemsPedido: ItemPedido[] = [],
		public telefonoContacto?: string,
		public entrega: Ubicacion = new Ubicacion(),
		public tipoEntrega: Descriptivo = new Descriptivo('P', "Pick up"),
		public observaciones?: string,
		public local?: Local,
		public menuId?: number,
		public pagador: string = "info@thinkup.com.ar",
		public paymentId?: string,
		public pushToken?: string,
		public esPager: boolean = false,
		public numeroPedidoExterno?: string,
		public email?: string,
		public trxId?: string,
		public nombreContacto?: string,
		public gastos: GastoPedido[] = [],
		public mesaId?: number,
		public descuento: number = 0,
		public bonificacion: number = 0
		
	) {
		if (!this.entrega) this.entrega = new Ubicacion();
	}

	public static fromData(data) {
		if (!data) return;
		const o = new Pedido(
			data.id,
			data.numeroPedido,
			data.fecha ? new Date(data.fecha) : null,
			EstadoDocumento.fromData(data.estado),
			data.total,
			Descriptivo.fromData(data.metodoPago),
			data.itemsPedido ? data.itemsPedido.map(i => ItemPedido.fromData(i)) : [],
			data?.telefonoContacto,
			Ubicacion.fromData(data.entrega),
			Descriptivo.fromData(data.tipoEntrega),
			data.observaciones,
			Local.fromData(data.local),
			data.menuId,
		);
		o.esPager = data.esPager;
		o.numeroPedidoExterno = data.numeroPedidoExterno;
		o.trxId = data.trxId;
		o.nombreContacto = data.nombreContacto;
		o.gastos = data.gastos? data.gastos.map(g => GastoPedido.fromData(g)) : []
		o.mesaId = data.mesaId;
		o.descuento = data.descuento
		o.bonificacion = data.bonificacion
		return o;
	}
	public agregarProducto(producto: Producto, sumar = false) {
		const prod = this.itemsPedido.filter(ip => ip.producto.id == producto.id)[0];
		if (!prod) {
			const newItem = ItemPedido.fromData({
				producto: ProductoCabecera.fromData(producto),
				cantidad: producto.cantidad ? producto.cantidad : 1,
				precioLista: producto.precioLista,
				precioDescuento: producto.precioDescuento,
				descuento: producto.descuento
			})
			this.itemsPedido.push(newItem);
		} else {
			if(producto.cantidad) prod.cantidad = producto.cantidad
			else prod.cantidad++;
		}
	}


	public getByProducto(p: Producto) {
		return this.itemsPedido.filter(ip => ip.producto.id == p?.id)[0];
	}
	public agregarItemPedido(itemPedido: ItemPedido) {
		const prod = this.itemsPedido.filter(ip => ip.producto.id == itemPedido.producto.id)[0];
		if (!prod) {

			this.itemsPedido.push(itemPedido);
		} else {
			prod.cantidad += itemPedido.cantidad;
		}
	}

	

	public getTotal() {
		return (this.itemsPedido.reduce((a, b) => a += b.total, 0));
	}

	public getTotalConGastos() {

		let gastos;

		if (this.gastos) {
			gastos = this.gastos.reduce((a,b) => a += b.importe, 0)
		}

		return ((this.itemsPedido.reduce((a, b) => a += b.total, 0)) + gastos - this.getDescuento()) ;
	}


	public getDescuento() {
		let total = this.getTotal() * this.descuento / 100
		return Math.round((total + Number.EPSILON) * 100) / 100 + this.bonificacion
	}

	public get cantidadItems() {
		return this.itemsPedido.reduce((a, b) => a += b.cantidad, 0);
	}
	public getCantidad(p){
		return this.itemsPedido.find(i=> i.producto?.id == p?.id)?.cantidad || 0;
	}
	public noFinalizado() {
		return this.estado?.codigo != 'C' && this.estado?.codigo != 'AN' && this.estado?.codigo != 'F' && this.estado?.codigo != 'R';
	}

	public tieneProducto(producto:Producto | ProductoCabecera){
		return this.itemsPedido?.some(ip => ip?.producto?.id == producto.id);
	}
}