import { ItemPedido } from 'src/app/model/ItemPedido';
import { Adicionales } from './Adicionales';
import { Descriptivo } from './Descriptivo';
import { FileData } from './FileData';
import { ProductoCabecera } from './ProductoCabecera';
import { Restriccion } from './Restriccion';
import { TipoProducto } from './TipoProducto';
import { Unidad } from './Unidad';
export class Producto extends Descriptivo {
    public imagenes?: FileData[] = [];
    public habilitado: boolean = false;
    public esRapido: boolean = false;
    public unidad?: Unidad;
    public tipo?: TipoProducto;
    public detalle?: string;
    public productoRecarga?: ProductoCabecera;
    public descripcionPreview?: string
    public restricciones:Restriccion[] = [];
    descuentoAplicado?: string;
    categoria?: string;
    precioLista?: number;
    precioDescuento?: number;
    descuento?: number;
    cantidad: number = 1;
    adicionales?: Adicionales[];
    recomendados?: ItemPedido[] = [];
    private _desc: string;
    public imagenPortada?:string;
    constructor(data: any) {
        super(data.codigo, data.descripcion, data.id);
        Object.assign(this, data);
        this.productoRecarga = ProductoCabecera.fromData(data.productoRecarga);
        this.imagenes = data.imagenes ? data.imagenes.map(i => FileData.fromData(i)) : [new FileData(null,null,null,null,null,null,this.imagenPortada)]
        
        this._desc = this.productoRecarga ? this.descripcion + " - " + this.productoRecarga.descripcion : this.descripcion;
        this.recomendados = data.recomendados ? data.recomendados.map(r => ItemPedido.fromData(r)) : []
        this.recomendados.forEach(r => r.cantidad = 0);
        this.restricciones = data.restricciones ? data.restricciones.map((r) => Restriccion.fromData(r)) : []
    }

    public getDescripcion() {
        return this._desc;
    }
    public get total() {
        const recom = this.recomendados.reduce((a, b) => a += b.total, 0);
        return this.cantidad * (this.precioDescuento ? this.precioDescuento : this.precioLista) + recom;
    }
    public get imagen() {
        return this.productoRecarga ? this.productoRecarga.imagen : this.imagenes[0]?.imagen
    }
    public static fromItem(it: ItemPedido) {
        if (it == null) return null;

        var p = new Producto(it);
        Object.assign(p, it.producto);
        p.imagenPortada = it.imagen
        return p;
    }
    public get sinUnidad() {
        return this.unidad?.codigo == "SN"
    }

}