import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';



@Component({
	selector: 'btn-remove-add',
	templateUrl: './btn-remove-add.component.html',
	styleUrls: ['./btn-remove-add.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => BtnRemoveAddComponent),
			multi: true
		}
	]
})
export class BtnRemoveAddComponent implements OnInit, ControlValueAccessor {

	@Input()
	public styleClass: string = '';
	@Output()
	public onChange: EventEmitter<boolean> = new EventEmitter();

	private _amount: number;
	public get amount(): number {
		return this._amount;
	}
	
	public set amount(v: number) {
		if (v != this._amount) {
			this.onChange.emit(true);
		}
		this._amount = v;
		this.onChangeCallback(this._amount);
	}
	writeValue(obj: any): void {
		this._amount = obj ? obj : [];

	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	private onChangeCallback: (_: any) => void = () => { };
	private onTouchedCallback: (_: any) => void = () => { };

	constructor() { }

	ngOnInit() {
		if (!this.amount) {
			this.amount = 1;
		}
	}
	@Input()
	public disabled = false;
	increase(event): void {
		this.amount++;
		event.stopPropagation();

	}
	decrease(event): void {
		if (this.amount > 1) {
			this.amount--;
		}
		event.stopPropagation();

	}

}
