import * as moment from 'moment'
import { Descriptivo } from './Descriptivo'
import { Horario } from './Horario'
import { ProfilePic } from './ProfilePic'
import { TurnoReserva } from './TurnoReserva'
import { Ubicacion } from './Ubicacion'

export class Local extends Descriptivo {
    constructor(
        id?: number,
        codigo?: string,
        descripcion?: string,
        public ubicacion: Ubicacion = new Ubicacion(),
        public horarios: Horario[] = [],
        public imagen?: ProfilePic,
        public imagenHeader?: ProfilePic,
        public turnosReserva: TurnoReserva[] = [],
        public admiteReserva: boolean = false,
        public tieneMenu: boolean = false,
        public admitePedido: boolean = false,
        public tieneMesas: boolean = false,
        public locacion?: number,
        public conServicioMesa: boolean = false,
        public admiteDelivery: boolean = false,
        public telefono?: string,
        public email?: string,
        public facebook?: string,
        public instagram?: string,
        public qrAfip?: string,
        public diasAntelacion?: number,
        public fechaMaximaReserva?: Date,
        public tieneMp = false,
        public identificadorUrl?:string
    ) {
        super(codigo, descripcion, id)
        if (ubicacion == null) {
            this.ubicacion = new Ubicacion()
        }
        this.resumenHorario = this.horarios?.length > 0 ? 'FALTA GENERAR HORARIOS' : 'SIN_HORARIOS'
    }

    private crearHorarios() {
        let dias: Map<String, String[]> = new Map()

        this.horarios.forEach((h) => {
            if (dias.has(h.getDiasSTR())) {
                dias.get(h.getDiasSTR()).push(h.getHorarioSTR())
            } else {
                dias.set(h.getDiasSTR(), [h.getHorarioSTR()])
            }
        })
    }
    private _resumenHorario: string
    public get resumenHorario(): string {
        return this._resumenHorario
    }
    public set resumenHorario(v: string) {
        this._resumenHorario = v
    }

    public get direccion() {
        return this.ubicacion?.direccion
    }
    public static fromData(data: any): Local {
        if (!data) return null
        const o: Local = new Local(
            data.id,
            data.codigo,
            data.descripcion,

            Ubicacion.fromData(data.ubicacion),
            data.horarios ? data.horarios.map((c) => Horario.fromData(c)) : [],
            ProfilePic.fromData(data.imagen),
            ProfilePic.fromData(data.imagenHeader),
            data.turnosReserva ? data.turnosReserva.map((t) => TurnoReserva.fromData(t)) : [],
            data.admiteReservas,
            data.tieneMenu,
            data.admitePedido,
            data.tieneMesas,
            data.locacion,
            data.conServicioMesa,
            data.admiteDelivery,
            data.telefono,
            data.email,
            data.facebook,
            data.instagram,
            data.qrAfip,
            data.diasAntelacion,
            data.fechaMaximaReserva,
            data.tieneMp,
            data.identificadorUrl
        )
        return o
    }

    public getTurnosByFecha(date: Date) {
        let dia = moment(date).isoWeekday()
        return this.turnosReserva.filter((t) => t.esDia(dia))
    }
    public getHorariosTurnos(fecha: Date) {
        if (!fecha) return []
        const vals = this.getTurnosByFecha(fecha).reduce((flat, turno) => flat.concat(turno.horarios), [])
        return vals ? Array.from(new Set(vals)) : []
    }

    public get imagenUrl() {
        return this.imagen ? this.imagen.picPath : 'assets/img/default_image.png'
    }

    public get imagenHeaderUrl() {
        return this.imagenHeader ? this.imagenHeader.picPath : this.imagen ? this.imagen.picPath : 'assets/img/default_image.png'
    }
}
