import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { filter, first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Mayor18Service } from './../../mayor18.service';
@Injectable({
    providedIn: "root"
})
export class MayorCheckAG implements CanActivate {

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ) {

        if (!environment.requiereValidacionEdad) {
            return true;
        } else {
            return this.mayorService.preguntado.pipe(filter(v => v != undefined), first(), map(v => {
                if (!this.mayorService.mayor18) {
                    return this.router.createUrlTree(['/not-allowed']);
                }
                return this.mayorService.mayor18;
            }));
        };
    }

    private visible: boolean = false;
    constructor(private mayorService: Mayor18Service, private router: Router) {

    }

}