import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { environment } from './../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Mayor18Service {
  initiated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  preguntado: BehaviorSubject<boolean> = new BehaviorSubject(null);

  private _mayor18: boolean;
  public fuePreguntado() {
    return this.mayor18 != undefined;
  }
  public get mayor18(): boolean {
    return this._mayor18;
  }
  public set mayor18(v: boolean) {
    this._mayor18 = v;
    this.localStorage.set(environment.tokenKey + "_" + "mayor18", v);
    if (v != undefined)
      this.preguntado.next(v);
  }

  constructor(private localStorage: Storage, private alertController: AlertController) {
    this.init();
  }
  public init() {
    this.localStorage.get(environment.tokenKey + "_" + 'mayor18').then(r => {
      if (r != undefined) {
        this.mayor18 = r
      }
      this.initiated.next(true);
    })
  }

  public async ask() {
    if (this.mayor18 == undefined) {
      const alert = await this.alertController.create({
        cssClass: 'check-mayor18',
        header: 'Sos mayor a 18 años?',
        buttons: [
          {
            text: 'SI',
            role: 'cancel',
            handler: (blah) => {
              this.mayor18 = true;
              this.preguntado.next(true);
            }
          }, {
            text: 'NO',
            cssClass: 'secondary',
            handler: () => {
              this.mayor18 = false;
              this.preguntado.next(false);
            }
          }
        ]
      });
      await alert.present();
    }

  }


}
