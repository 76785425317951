import * as moment from 'moment';
import { Descriptivo } from './Descriptivo';
export class Ocupacion {
    constructor(
        public fecha?: Date,
        public horario?: string,
        public cantidadPersonas?: number,
        public cantidadReservas?: number,
        public local?: Descriptivo,
    ) {
    }
    public static fromData(data: any): Ocupacion {
        if (!data) return null;
        const o: Ocupacion = new Ocupacion(
            data.fecha ? moment(data.fecha, 'YYYY-MM-DD').toDate() : null,
            data.horario,
            data.cantidadPersonas,
            data.cantidadReservas,
            Descriptivo.fromData(data.local)

        );

        return o;

    }
}