import { Descriptivo } from './Descriptivo'
import { FileData } from './FileData'
import { Restriccion } from './Restriccion'
import { TipoProducto } from './TipoProducto'
import { Unidad } from './Unidad'

export class ProductoCabecera extends Descriptivo {
	
    private _desc: string
    constructor(
        id?: number,
        codigo?: string,
        descripcion?: string,
        public imagenPortada?: string,
        public habilitado: boolean = false,
        public esRapido: boolean = false,
        public unidad?: Unidad,
        public tipo?: TipoProducto,
        public detalle?: string,
        public productoRecarga?: ProductoCabecera,
        public descripcionPreview?: string,
        public categorias?: string,
        public restricciones?: Restriccion[]
    ) {
        super(codigo, descripcion, id)
        this._desc = this.productoRecarga ? this.descripcion + ' - ' + this.productoRecarga.descripcion : this.descripcion
        this.descripcionPreview = this.productoRecarga ? this.productoRecarga.descripcionPreview : descripcionPreview
        if (categorias) this.categorias = categorias.toUpperCase()
    }
    public static fromData(data: any): ProductoCabecera {
        if (!data) return null
        const o: ProductoCabecera = new ProductoCabecera(
            data.id,
            data.codigo,
            data.descripcion,
            data.imagenPortada,
            data.habilitado,
            data.esRapido,
            Unidad.fromData(data.unidad),
            TipoProducto.fromData(data.tipo),
            data.productoRecarga ? data.productoRecarga.detalle : data.detalle,
            ProductoCabecera.fromData(data.productoRecarga),
            data.descripcionPreview,
            data.categorias,
            data.restricciones ? data.restricciones.map((r) => Restriccion.fromData(r)) : []
        )

        return o
    }
    aplicaRestricciones(restricciones: Restriccion[], restriccionesExcluyentes: Restriccion[] = []) {
        let res = !this.restricciones?.length || !this.restricciones?.filter(r=> !r.excluyente).length || this.restricciones?.filter(r=> !r.excluyente)?.every(r=> restricciones.some(res=> res.id ===r.id));
		
        let res2 = !restriccionesExcluyentes?.length;

        if (!res2)
            res2 = restriccionesExcluyentes.every(r => 
                this.restricciones.some(res => res.id == r.id)
            )


        return res && res2;
	}
    public get imagen() {
        return this.productoRecarga ? this.productoRecarga.imagen : this.imagenPortada
    }
    public getImagenOrDefault(def:string = "assets/images/default.png") {
        const img = this.productoRecarga ? this.productoRecarga.imagen : this.imagenPortada
        return img  || def;
    }
    public get imagenes() {
        return [FileData.fromData({ url: this.imagenPortada })]
    }
    public getDescripcion() {
        return this._desc
    }

    public getPreview() {
        return this.descripcionPreview ? this.descripcionPreview : this.getSubDetalle()
    }
    public getSubDetalle() {
        if (this.detalle?.length > 150) {
            return this.detalle.substring(0, 150) + ' ...'
        } else {
            return this.detalle
        }
    }

    public get sinUnidad() {
        return this.unidad?.codigo == "SN"
    }
}
