import { Injectable } from '@angular/core';

@Injectable({
    providedIn: "root"
})
export class ContextService {

    private map: Map<string, any> = new Map();

    public get(key: string) {
        let v = this.map.get(key);
        return v;
    }

    public set(key: string, val: any) {
        this.map.set(key, val);
    }

    public clean(key: string) {
        this.map.delete(key);
    }
}