import { Directive, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Local } from './model/Local';
import { LocalService } from './services/local.service';

@Directive({
  selector: '[admitePedido]'
})
export class AdmitePedidoDirective {

  private local: Local;
  private original: string;
  constructor(private elementRef: ElementRef, private localService: LocalService) {
    this.original = this.elementRef.nativeElement.style.display;
  }
  ngOnInit(): void {
    this.localService.localSeleccionado.subscribe((l) => {
      this.local = l;
      if (!this.local?.admitePedido || environment.soloMenu) {
        this.elementRef.nativeElement.style.display = 'none';
      } else {
        this.elementRef.nativeElement.style.display = this.original;
      }
    });
  }

}
