import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFirestore } from '@angular/fire/firestore'
import { Router } from '@angular/router'
import { ToastController } from '@ionic/angular'
import { Storage } from '@ionic/storage'
import firebase from 'firebase/app'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { Register } from 'src/app/model/Register'
import { environment } from 'src/environments/environment'
import { Usuario } from './../model/Usuario'
import { BaseService } from './base.service'

@Injectable({
    providedIn: 'root',
})
export class AuthService extends BaseService {
    register = new Register()
    registering = false
    private _uid: string = ''
    tokenObs: BehaviorSubject<string> = new BehaviorSubject(null)
    uidObs: BehaviorSubject<string> = new BehaviorSubject(null)

    private _username: string
    public get username(): string {
        return this._username
    }

    public baseName(): string {
        return 'login'
    }
    public tokenBackend: string = null

    private _renewToken: string
    public get renewToken(): string {
        return this._renewToken
    }

    public interval
    public intentos = 0

    public onLogout: Subject<any> = new Subject()
    public onLogin: Subject<any> = new Subject()
    public static AUTENTICANDO: string = 'IP'
    public static LOGUEADO: string = 'L'
    public static NO_LOGUEADO: string = 'N'
    public static curUser: Usuario
    private static autenticateState: BehaviorSubject<string>
    private static userBS: BehaviorSubject<Usuario>

    private _email: string
    public get email(): string {
        return this._email
    }
    public set email(v: string) {
        this._email = v
    }

    constructor(
        private router: Router,
        public http: HttpClient,
        private localStorage: Storage,
        private afs: AngularFirestore,
        public auth: AngularFireAuth,
        private alertController: ToastController
    ) {
        super(http)
        // this.init();
        AuthService.autenticateState = new BehaviorSubject<string>(AuthService.AUTENTICANDO)
        AuthService.userBS = new BehaviorSubject<Usuario>(this.usuario)
    }

    async init() {
        const proms: Promise<any>[] = [
            this.leerUid().then((r) => {
                this.uid = r
                return r
            }),
            this.leerEmail().then((r) => {
                this.email = r
                return r
            }),
            this.getRenewToken().then((r) => {
                this.renewToken = r
                return r
            }),
            this.getUsername().then((r) => {
                this.username = r
                return r
            }),
        ]
        Promise.all(proms).then(async (r) => {
            this.refreshToken()
                .toPromise()
                .then((t) => {
                    this.getUsuario()
                })
        })
    }

    public get uid(): string {
        return this._uid
    }
    public set uid(v: string) {
        this._uid = v
        this.uidObs.next(this.uid)
    }
    getApiURL() {
        return environment.apiUrl
    }
    public getAutenticateState() {
        return AuthService.autenticateState
    }

    async guardarUid(uid: string) {
        await this.localStorage.set('uid', uid)
    }

    // async leerToken() {
    //   return await this.localStorage.get('tokenbackend')
    // }

    async leerEmail() {
        return await this.localStorage.get('email')
    }

    async leerUid() {
        return await this.localStorage.get('uid')
    }

    public get usuario(): Usuario {
        return AuthService.curUser
    }

    public set usuario(v: Usuario) {
        if (AuthService.curUser?.id != v?.id) {
            AuthService.userBS.next(v)
            AuthService.curUser = v
        }
        this.username = v?.username
    }

    public set renewToken(val: string) {
        if (val) {
            this.localStorage.set('tokenrenew', val)
        } else {
            this.localStorage.remove('tokenrenew')
        }
        this._renewToken = val
    }
    public async getRenewToken() {
        return await this.localStorage.get('tokenrenew')
    }

    public set username(val: string) {
        if (val) {
            this.localStorage.set(environment.tokenKey + '_username', val)
        } else {
            this.localStorage.remove(environment.tokenKey + '_username')
        }
        this._username = val
    }
    public async getUsername() {
        return await this.localStorage.get(environment.tokenKey + '_username')
    }

    public set push(val: string) {
        if (val) {
            this.localStorage.set(environment.tokenKey + '_push', val)
        } else {
            this.localStorage.remove(environment.tokenKey + '_push')
        }
        this.tokenObs.next(val)
    }
    public async getPush() {
        return await this.localStorage.get(environment.tokenKey + '_push')
    }
    getUser(): Observable<Usuario> {
        return AuthService.userBS.asObservable()
    }

    protected handleOk = (response: any): string => {
        let res = response
        return res.mensaje
    }
    protected handleError(error: any): Promise<any> {
        return Promise.reject(error.error || error)
    }
    recuperarPass(email): Promise<any> {
        let $this = this
        return this.http
            .post(this.getApiURL() + 'login/recuperar-password', email)
            .toPromise()
            .then((r: any) => {
                return Promise.resolve(r ? Usuario.fromData(r) : null)
            })
    }
    async loginDefault() {
        return this.login(environment.userApp, environment.passwordApp).then((r) => {
            this.register.default = true
            return r
        })
    }

    async login(user: string, password: string): Promise<Usuario> {
        let $this = this
        return this.http
            .post(this.getApiURL() + 'login', { username: user, password: password })
            .toPromise()
            .then((r: any) => {
                if (r) {
                    $this.tokenBackend = r.token
                    $this.usuario = Usuario.fromData(r.usuario)
                    if (r.renewToken) {
                        $this.renewToken = r.renewToken
                    }
                    //this.setRenew(r);
                    this.getAutenticateState().next(AuthService.LOGUEADO)
                    return Promise.resolve($this.usuario)
                } else {
                    this.getAutenticateState().next(AuthService.NO_LOGUEADO)
                }
                return Promise.resolve(null)
            })
    }

    async getUsuario() {
        return await this.http.get(`${this.getApiURL()}getUser`).subscribe((r: any) => {
            if (r) {
                if (r.renewToken !== null) {
                    this.guardarTokenRenew(r.renewToken)
                    this.renewToken = r.renewToken
                }
                this.register.email = r.email
                this.register.urlImagen = r.profilePic?.picPath
                this.register.telefono = r.telefono
                this.register.uid = r.uid
                this.register.nombre = r.nombre
                this.username = this.register.email
                if (r.username == 'appuser') {
                    this.register.default = true
                } else this.register.default = false
                this.usuario = Usuario.fromData(r)
                this.setRenew(r)
                this.getAutenticateState().next(AuthService.LOGUEADO)
            } else {
                this.getAutenticateState().next(AuthService.NO_LOGUEADO)
            }
            return r
        })
    }

    async loginBackend() {
        if (!this.register.uid) {
            const a = await this.alertController.create({
                header: 'Error',
                message: 'No se pudo loguear. Intente mas tarde',
                duration: 2000,
            })

            return await a.present()
        }
        return this.http
            .post(`${this.getApiURL()}login/get-user-app`, { uid: this.register.uid, idToken: this.register.token })
            .toPromise()
            .then((r: any) => {
                if (r) {
                    if (r.usuario) {
                        // this.guardarTokenBackend(r.token);
                        this.tokenBackend = r.token
                        this.guardarTokenRenew(r.renewToken)
                        this.renewToken = r.renewToken
                        this.setRenew(r)
                        this.register.email = r.usuario.email
                        this.register.nombre = r.usuario.nombre
                        this.register.urlImagen = r.usuario.profilePic.picPath
                        this.register.telefono = r.usuario.telefono
                        this.username = this.register.email
                        if (r.username == 'appuser') {
                            this.register.default = true
                        } else this.register.default = false
                        this.usuario = Usuario.fromData(r.usuario)
                    } else {
                        this.registering = true
                    }
                    this.getAutenticateState().next(AuthService.LOGUEADO)
                } else {
                    this.getAutenticateState().next(AuthService.NO_LOGUEADO)
                }
                return r
            })
    }
    async loginFirebase(red: string) {
        let authProvider

        switch (red) {
            case 'google':
                authProvider = new firebase.auth.GoogleAuthProvider()
                break
            case 'facebook':
                authProvider = new firebase.auth.FacebookAuthProvider()
                break
        }

        if (authProvider) {
            return this.auth
                .signInWithPopup(authProvider)
                .then(async (resp: any) => {
                    // firebase.auth.UserCredential) => {
                    this.register.token = await resp.user.getIdToken()

                    this.guardarUid(resp.user.uid)
                    this.uid = resp.user.uid
                    this.register.uid = resp.user.uid
                    this.register.email = resp.user.email
                    this.guardarEmail(this.register.email)
                    this.register.telefono = resp.user.phoneNumber
                    this.register.urlImagen = resp.user.photoURL
                    this.register.nombre = resp.user.displayName
                    // this.register.default = false;
                    if (red === 'facebook') {
                        let token = resp.credential.accessToken
                        await this.http
                            .get(`https://graph.facebook.com/${resp.additionalUserInfo.profile.id}?fields=picture.height(150).width(150)&access_token=${token}`)
                            .subscribe((r: any) => {
                                this.register.urlImagen = r.picture.data.url
                            })
                    }
                    return resp
                })
                .catch((error) => {
                    return error
                })
        }
    }

    async logout(): Promise<any> {
        const usuario = AuthService.curUser
        this.tokenBackend = null
        this.push = null
        this.usuario = null
        this.username = null
        AuthService.curUser = null
        this.renewToken = null
        this.uid = null

        if (this.interval) {
            clearInterval(this.interval)
        }
        await this.localStorage.remove('email')
        await this.localStorage.remove('uid')
        // await this.localStorage.remove('tokenbackend');
        await this.localStorage.remove('tokenrenew')

        this.register.email = null
        this.register.nombre = null
        this.register.token = null
        this.register.uid = null
        this.register.urlImagen = null
        this.register.default = true
        this.onLogout.next(usuario)
        this.loginDefault()
        return Promise.resolve(true)
    }

    private setRenew(r) {
        if (this.interval) clearInterval(this.interval)
        this.interval = setInterval(
            () => {
                this.refreshToken()
            },
            r.renewTime && r.renewTime >= 900000 ? r.renewTime - 60000 : 840000
        )
    }
    public refreshToken(): Observable<any> {
        let $this = this
        let data: BehaviorSubject<string> = new BehaviorSubject<string>(null)
        if (this.intentos == 4 || !this.renewToken) {
            this.loginDefault().then((l) => {
                data.complete()
            })
        } else {
            this.intentos++
            this.http
                .post(this.getApiURL() + 'reauthenticate', { username: this.username, renewToken: this.renewToken })
                .toPromise()
                .then((r: any) => {
                    $this.tokenBackend = r.token
                    $this.usuario = Usuario.fromData(r.usuario)
                    this.intentos = 0
                    if (r.renewToken) {
                        $this.renewToken = r.renewToken
                    }
                    this.getAutenticateState().next(AuthService.LOGUEADO)
                    data.next(r)
                    data.complete()
                })
                .catch(async (r) => {
                    if (this._renewToken && !this.esUserDefault()) {
                        const a = await this.alertController.create({
                            header: 'Sessión caducada',
                            message: 'Tu sesión ya caducó. Volvé a loguearte',
                            duration: 2000,
                        })
                        a.present()
                    }
                    this.tokenBackend = null
                    this.push = null
                    this.usuario = null
                    this.logout().then((r) => {
                        this.loginDefault()
                        data.complete()
                    })
                })
        }

        return data
    }

    get isLoggedIn(): boolean {
        return this.usuario != undefined && this.tokenBackend != undefined
    }
    get getCurrentUser(): Usuario {
        return this.usuario
    }
    get esAdministrador() {
        return this.getCurrentUser && this.getCurrentUser.roles.some((r) => r.codigo === 'ROLE_ADMIN')
    }
    public tieneRol(rol: string) {
        return this.getCurrentUser.tieneRol(['ROLE_ADMIN', rol])
    }

    getUserFullName(): string {
        let user = this.getCurrentUser ? this.getCurrentUser : null
        return user ? user.nombre : 'SIN INFORMAR'
    }

    hayLogueado() {
        if (this.tokenBackend !== null) {
            return true
        } else return false
    }

    registrar() {
        return this.http
            .post(`${this.getApiURL()}api/register/`, this.register)
            .toPromise()
            .then((r: any) => {
                // this.guardarTokenBackend(r.token);
                this.guardarTokenRenew(r.renewToken)
                this.tokenBackend = r.token
                this.renewToken = r.renewToken
                this.register.default = false
                this.registering = false
            })
    }

    // async guardarTokenBackend(token: string) {
    //   await this.localStorage.set('tokenbackend', token);
    // }

    async guardarTokenRenew(token: string) {
        await this.localStorage.set('tokenrenew', token)
    }

    async guardarEmail(email: string) {
        await this.localStorage.set('email', email)
        this.email = email
    }

    esUserDefault() {
        return this.register.default
    }

    validarToken() {
        return this.http
            .post(`${this.getApiURL()}api/register/`, this.register)
            .toPromise()
            .then((r: any) => {
                // this.guardarTokenBackend(r.token);
                this.tokenBackend = r.token
            })
    }

    getImagen() {
        if (this.register.urlImagen) {
            return this.register.urlImagen
        } else return 'assets/logo.png'
    }

    getNombre() {
        return this.register.nombre
    }

    getUserObs() {
        return AuthService.userBS
    }
}
