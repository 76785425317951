import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Vibration } from "@ionic-native/vibration/ngx";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { filter, first, map } from "rxjs/operators";
import { Pedido } from "src/app/model/Pedido";
import { AuthService } from "src/app/services/auth.service";
import { PedidoService } from "src/app/services/pedido.service";
import { environment } from "./../../../environments/environment";
import { Novedad } from "./../../model/Novedad";
import { NovedadService } from "./../../services/novedades.service";

@Component({
  selector: "mis-pedidos",
  templateUrl: "mis-pedidos.component.html",
  styleUrls: ["mis-pedidos.component.scss"],
})
export class MisPedidosComponent implements OnInit, OnDestroy {
  public numeroPedido: number;

  public codigoVerificacion: string;
  public updateSub;
  private _pedidos: Pedido[] = [];
  public get pedidos(): Pedido[] {
    return this._pedidos;
  }
  public set pedidos(v: Pedido[]) {
    this._pedidos = v;
  }

  constructor(
    private pedidoService: PedidoService,
    private novedadService: NovedadService,
    private platform: Platform,
    private alertController: AlertController,
    private vibration: Vibration,
    private modalController: ModalController,
    private router: Router,
    private authService: AuthService
  ) {}

  public verPedido(p: Pedido) {
    this.pedidoService.pedidoSeleccionado = p;
    this.router.navigate(["pedido"]);
  }
  private sub: Subscription;
  public async verAgregarPedido() {
    this.router.navigate(["agregar-pedido"]);
  }
  public actualizarEstados(event) {
    if (event.stopPropagation) event.stopPropagation();
    if (event.preventDefault) event.preventDefault();
    this.pedidoService
      .actualizarEstados()
      .pipe(
        filter((v) => v == true),
        first()
      )
      .subscribe(
        (r) => {
          event.target.complete();
        },
        (e) => {
          event.target.complete();
        }
      );
  }
  public prevNovedad: Novedad;
  public counter;
  public get permiteAgregarPedido() {
    return environment.pedidosExternos;
  }
  ngOnInit() {
    this.sub = this.pedidoService.pedidos
      .pipe(
        map((p) => {
          if (!p?.length) {
            return [];
          } else {
            return p;
          }
        })
      )
      .subscribe((p) => {
        this.pedidos = p
          .filter((ped) =>
            moment(ped.fecha).isAfter(moment().subtract(15, "days")) && ped.id
          )
          .sort((a, b) => (a.fecha > b.fecha ? -1 : 1));
      });
  }
  ngOnDestroy() {
    if (!this.sub) this.sub.unsubscribe();
    if (this.counter) clearInterval(this.counter);
    this.counter = null;
  }

  public ionViewDidEnter() {
    this.pedidoService.actualizarEstados();
  }
  async toast(m: string) {
    const t = await this.alertController.create({
      message: m,
      buttons: [
        {
          text: "Ok",
          role: "ok",
          cssClass: "secondary",
          handler: (v) => {
            if (this.counter) {
              clearInterval(this.counter);
            }
          },
        },
      ],
    });
    t.present();
  }
}
