import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Menu } from './../../model/Menu';
import { MenuService } from './../../services/menu.service';
import { PedidoService } from './../../services/pedido.service';

@Component({
  selector: 'app-se-viene',
  templateUrl: './se-viene.page.html',
  styleUrls: ['./se-viene.page.scss'],
})
export class SeVienePage implements OnInit {
  loading: Boolean;
  public menus: Menu[] = [];
  constructor(
    private router: Router,
    private menuController: MenuController,
    private menuService: MenuService,
    private pedidoService: PedidoService,
  ) {

  }

  ionViewWillEnter() {
    this.menuController.enable(false);
  }


  ngOnInit() {

  }




}
