import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from './../../environments/environment.test';
import { Novedad } from './../model/Novedad';
import { AuthService } from './auth.service';
import { BaseService } from './base.service';
@Injectable({
    providedIn: 'root',
})
export class NovedadService extends BaseService {
    public get currentLocal(): number {
        return this._currentLocal;
    }
    public set currentLocal(v: number) {
        if ((!this._currentLocal || this.currentLocal != v) && this._pushToken) {
            this.observers
                .filter((o) => !o.idEntidad)
                .forEach((o) => {
                    if (this.currentLocal) {
                        this.http
                            .post(this.getBaseURL('desuscribir'), { tipo: o.tipo, destinatario: this._pushToken, idLocal: this.currentLocal })
                            .toPromise()
                            .catch((e) => {});
                    }

                    if (v) {
                        this.http
                            .post(this.getBaseURL('suscribir'), { tipo: o.tipo, destinatario: this._pushToken, idLocal: v })
                            .toPromise()
                            .catch((e) => {});
                    }
                });
        }
        this._currentLocal = v;
    }

    constructor(http: HttpClient, private storage: Storage, private authService: AuthService) {
        super(http);
        NovedadService.updateNovedades.subscribe(this.notify);
        this.authService.tokenObs.subscribe((t) => {
            this.updateToken(t);
        });
        this.authService.onLogout.subscribe((l) => {
            this._pushToken = null;
        });
    }
    private static updateNovedades: BehaviorSubject<Novedad[]> = new BehaviorSubject([]);
    public initiated: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _pushToken: string;
    private _currentLocal: number;
    private observers: ObseKey[] = [];
    private susObservers: Subscription;
    private checking: Promise<any>;
    private lastUpdate: Date;
    private isChecking: boolean;
    public baseName(): string {
        return 'novedades';
    }
    public getPushToken(): Promise<string> {
        return this._pushToken ? Promise.resolve(this._pushToken) : this.authService.getPush();
    }
    private updateToken(token: string) {
        if (this._pushToken) { this.http.post(this.getBaseURL('updateToken'), { anterior: this._pushToken, nuevo: token }).toPromise() }

        if (this._pushToken != token) {
            if (!this._pushToken) {
                this._pushToken = token;
                this.observers.forEach((o) => {
                    o.observadores.forEach((obs) => {
                        this.registrarObservador(o.tipo, obs, o.idEntidad);
                    });
                });
            } else {
                this._pushToken = token;
            }
        }
    }
    public async startup() {}
    public nuevoPush(msg: any) {
        if (msg.data) {
            NovedadService.updateNovedades.next([Novedad.fromData(msg.data)]);
        }
    }
    private _register(tipo: string, suscriber: IActualizable, idEntidad?: number, idLocal?: number, pushObserver: boolean = false) {
        let subs = this.observers.filter((o) => o.tipo == tipo && idEntidad == o.idEntidad)[0];
        if (!subs || subs.observadores.indexOf(suscriber) < 0) {
            if (!subs) {
                subs = new ObseKey({ tipo, idEntidad });
                this.observers.push(subs);
            }
            subs.observadores.push(suscriber);
            if (pushObserver) {
                this.getPushToken().then((t) => {
                    if (t) {
                        this.http
                            .post(this.getBaseURL('suscribir'), { tipo, idEntidad, destinatario: t, idLocal: this.currentLocal })
                            .toPromise()
                            .catch((e) => {});
                    }
                });
            }
        }
    }
    public registrarObservador(tipo: string, suscriber: IActualizable, idEntidad?: number, idLocal?: number) {
        this._register(tipo, suscriber, idEntidad, idLocal);
    }
    public registrarPushObserver(tipo: string, suscriber: IActualizable, idEntidad?: number, idLocal?: number) {
        this._register(tipo, suscriber, idEntidad, idLocal, true);
    }
    public check(): Promise<any> {
        if (!this.lastUpdate) {
            this.lastUpdate = new Date();
        }
        if (!this.isChecking && (!this.lastUpdate || moment(new Date()).diff(this.lastUpdate, 'seconds') > 20)) {
            this.isChecking = true;
            return this.http
                .get(this.getBaseURL('all') + (this.lastUpdate ? '?lastCheck=' + moment(this.lastUpdate).format('YYYYMMDDHHmm') : ''))
                .toPromise()
                .then((n: Novedad[]) => {
                    this.lastUpdate = new Date();
                    this.storage.set(environment.tokenKey + 'lastcheck', this.lastUpdate);
                    return this.notify(n).then((r) => {
                        this.isChecking = false;
                        return r;
                    });
                })
                .catch((e) => {
                    this.isChecking = false;
                    return Promise.resolve('No se puedo actualizar');
                });
        }
        return Promise.resolve(true);
    }
    private notify = (r: Novedad[]): Promise<boolean> => {
        const proms = [];
        r.forEach((n) => {
            let o = this.observers.filter((o) => o.tipo == n.tipo && (o.idEntidad == n.idEntidad || !o.idEntidad));
            if (o) {
                o.forEach((oo) => oo.observadores.forEach((a) => proms.push(a.next(n))));
            }
        });
        return Promise.all(proms).then((r) => Promise.resolve(true));
    }
}
export class ObseKey {
    tipo: string;
    idEntidad?: number;
    observadores: IActualizable[] = [];
    constructor(data: any) {
        this.tipo = data.tipo;
        this.idEntidad = data.idEntidad;
    }
}
export interface IActualizable {
    next(n: Novedad): Promise<any>;
    destroy();
}
