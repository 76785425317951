import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';


@Injectable()
export class TokenRefreshInterceptor implements HttpInterceptor {

    private _refreshSubject: Subject<any> = new Subject<any>();
    constructor(private authenticationService: AuthService,) {
    }
    private _checkTokenExpiryErr(error: HttpErrorResponse): boolean {
        return (
            error.status &&
            error.status === 401 &&
            error.error &&
            error.error.message === "TokenExpired"
        );
    }
    private addToken(request: HttpRequest<any>) {
        const req = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.authenticationService.tokenBackend}`
            }
        });
        return req;
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (this._checkTokenExpiryErr(err)) {

                this._refreshSubject.subscribe({
                    complete: () => {
                        this._refreshSubject = new Subject<any>();
                    },
                    error: () => {
                        this._refreshSubject = new Subject<any>();
                    }
                });
                if (this._refreshSubject.observers.length === 1) {
                    this.authenticationService.refreshToken().subscribe(this._refreshSubject);
                }
                return this._refreshSubject.pipe(switchMap(() => {
                    return next.handle(this.addToken(request));
                }))
            } else {
                return throwError(err);
            }
        }));
        /*  if (err.status === 401) {
              this.authenticationService.refreshToken().subscribe((newToken: string) => {
                  this.authenticationService.token = newToken;
                  this.loadingService.susLoadingCount();
                  return;
              })

          } else {
              const error = err.error.message || err.statusText;
              this.loadingService.susLoadingCount();
              return throwError(error);
          }
      */

        // }))
    }
}