import { Component, Input, OnInit } from '@angular/core';
import { Restriccion } from 'src/app/model/Restriccion';

@Component({
	selector: 'restricciones',
	templateUrl: 'restricciones.component.html',
	styleUrls: ['restricciones.component.less'],
})

export class RestriccionesComponent implements OnInit {

	@Input() public items: Restriccion[] = []

	@Input() public showDescription:boolean = false;

	public toggleDescription(event){
		event?.stopPropagation && event.stopPropagation();
		this.showDescription = !this.showDescription;
	}
	ngOnInit() { }
}