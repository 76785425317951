import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
    providedIn: "root"
})
export class EstaLogueadoAG implements CanActivate {

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ) {
        if ((!this.authServive.hayLogueado() || this.authServive.esUserDefault()) && !this.authServive.registering) {
            return this.router.createUrlTree(['/login']);
        } else return true;
    }

    constructor(private authServive: AuthService, private router: Router) {

    }
}
