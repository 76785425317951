export class Novedad {

    constructor(
        public alta: Date = new Date(),
        public tipo: string,
        public key: string,
        public idLocal: number,
        public idEntidad: number) {

    }

    public static fromData(data) {
        if (!data) return
        return new Novedad(
            data.alta ? new Date(data.alta) : null,
            data.tipo,
            data.key,
            data.local ? Number(data.idLocal) : null,
            data.idEntidad ? Number(data.idEntidad) : null
        )
    }


}