import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalController } from "@ionic/angular";
import * as moment from "moment";
import { combineLatest, Subscription } from "rxjs";
import { filter, first, map } from "rxjs/operators";
import { MenuService } from "src/app/services/menu.service";
import { ModalPedidoComponent } from "./../../components/modal-pedido/modal-pedido.component";
import { LocalService } from "./../../services/local.service";
import { NavigationService } from "./../../services/navigation.service";
import { PedidoService } from "./../../services/pedido.service";

@Component({
  selector: "app-estado-pago",
  templateUrl: "./estado-pago.component.html",
  styleUrls: ["./estado-pago.component.scss"],
})
export class EstadoPagoComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private pedidoService: PedidoService,
    private modalController: ModalController,
    private localService: LocalService,
    private navService: NavigationService,
    private router: Router,
    private menuService: MenuService
  ) {}
  private sus: Subscription[] = [];
  public status: string;
  public error: boolean = false;

  ngOnInit() {
    this.pedidoService.ready
      .pipe(
        filter((v) => v == true),
        first()
      )
      .subscribe(() => {
        const urlParametrs = combineLatest([
          this.route.queryParams,
          this.route.params,
        ])
          .pipe(
            map((v) => {
              return { queryParams: v[0], params: v[1] };
            })
          )
          .subscribe(async (v) => {
            this.status = v.queryParams["status"];
            if (
              v.queryParams["status"] == "approved" &&
              v.queryParams["payment_id"]
            ) {
              const trxId = v.params["trxId"];

              this.sus.push(
                this.pedidoService.pedidos
                  .pipe(
                    filter((p) => p?.length > 0),
                    first()
                  )
                  .subscribe(async (pedidos) => {
                    let ped = pedidos.filter((p) => p.trxId == trxId)[0];
                    if (!ped) {
                      this.error = true;
                      return;
                    }
                    ped.paymentId = v.queryParams["payment_id"];
                    this.status = "a";
                    // if (ped.id != null) {
                    //   this.navService.goToLanding();
                    // }
                    if (!ped.local)
                      ped.local = await this.localService.getSeleccionado();
                    if (!ped.menuId) {
                      var m = await this.menuService.getMenuSeleccionado();
                      ped.menuId = m.id;
                    }
                    this.pedidoService
                      .registrarCobroMP(ped)
                      .then(async (ped) => {
                        this.status = "l";
                        this.pedidoService.guardarPedido(ped);
                        this.pedidoService.clearPedido();
                        const modal = await this.modalController.create({
                          component: ModalPedidoComponent,
                          cssClass: "modal-class",
                          componentProps: {
                            pedido: ped.numeroPedido,
                            hora:
                              ped.tipoEntrega?.codigo == "D"
                                ? moment().format("HH:mm")
                                : null,
                          },
                        });

                        await modal.present();
                        await modal.onWillDismiss().then((data) => {
                          // this.pedidoService.clearPedido();
                          this.pedidoService.resetPedido(ped)
                          this.pedidoService.pedidoSeleccionado = ped;
                          this.router.navigateByUrl("/pedido?idPedido=" + ped.id, { /* Removed unsupported properties by Angular migration: queryParams. */ replaceUrl: true });
                        });
                      })
                      .catch((e) => {
                        this.error = true;
                      });
                  })
              );
            }
          });
        this.sus.push(urlParametrs);
      });
  }

  ngOnDestroy() {
    this.sus.forEach((s) => {
      s.unsubscribe;
    });
    this.sus = [];
  }
}
