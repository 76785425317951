import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { MenuService } from 'src/app/services/menu.service';
import { LocalService } from './local.service';
@Injectable({
    providedIn: "root"
})
export class NavigationService {

    constructor(private localService: LocalService, private menuService: MenuService, private router: Router) {

    }

    public goToLanding() {
        this.localService.local = null;
        this.menuService.menuSeleccionado = null;
        /*if (this.localService.local?.id && !this.localService.admiteReservas) {
            this.localService.local = this.localService.local;
        }*/
        if (this.localService.local) {
            this.menuService.getMenus(this.localService.local).then(r => {
                if (r.length == 1) {
                    this.menuService.seleccionarMenu(r[0].id, r[0].idLocal);
                    this.goToMenu();

                } else {
                    this.router.navigate(["/landing"], { replaceUrl: true });
                }
            })
        } else {
            this.router.navigate(["/landing"]);
        }
    }

    public goToMenu() {
        /*this.menuService.menuSeleccioandoObs.pipe(filter(m => m?.id != undefined), first()).subscribe(r => {
           
        })
*/
            if (this.localService.local && this.menuService.menuSeleccionado ) {
                this.router.navigate(['/menu/' + this.localService.local.id + "/" + this.menuService.menuSeleccionado.id]);
            } else {
                this.router.navigate(["/menu"]);
            }        

    }

    public goToHome() {
        if (this.localService.local) {
            this.router.navigate(["/home"],  { queryParams: { idLocal: this.localService.local.id, idMenu: this.menuService.menuSeleccionado?.id } });
        } else {
            this.goToLanding();
        }
    }
}