import { Descriptivo } from './Descriptivo';
import { ProfilePic } from './ProfilePic';
import { Rol } from './Rol';

export class Usuario extends Descriptivo {

    public static MODEL_NAME = "usuario";

    constructor(
        id?: number,
        public username?: string,
        public nombre?: string,
        public apellido?: string,
        public activo: boolean = true,
        public bloqueado: boolean = false,
        public email?: string,
        public profilePic = new ProfilePic(null, '../../assets/icon/person-outline.svg'),
        public telefono?: string,
        public roles: Rol[] = [],
        public esVerificado: boolean = false,
        public descuento: number = 0

    ) {
        super(id + "", username, id);
        this.telefono = telefono;
        this.roles = roles;
        this.esVerificado = esVerificado;
    }
    entityName(): string {
        return Usuario.MODEL_NAME;
    }
    public static fromData(data: any): Usuario {
        if (!data) return null;
        let u: Usuario = new Usuario(data.id,
            data.username,
            data.nombre,
            data.apellido,
            data.activo,
            data.bloqueado,
            data.email,
            data.profilePic ? ProfilePic.fromData(data.profilePic) : new ProfilePic(),
            data.telefono,
            data.roles ? data.roles.map(Rol.fromData) : [],
            data.esVerificado,
            data.descuento);
        return u;
    }

    esAdmin() {
        return this.roles.some(r => r.codigo == Rol.ADMIN_CODE);
    }
    tieneRol(roles: string[]) {
        for (let rol = 0; rol < roles.length; rol++)
            if (this.roles.filter(r => r.codigo === roles[rol]).length > 0)
                return true;

        return false;
    }

    esSoloVisualizador() {
        return this.roles.length === 1 && this.roles.some(r => r.codigo == Rol.VISUALIZADOR_CODE);
    }

    get nombreCompleto(): string {

        if (this.nombre && this.apellido)
        return `${this.nombre} ${this.apellido}`;
        else if (this.nombre) return this.nombre;
        else if (this.apellido) return this.apellido;
    }



    public get imagen() {
        return this.profilePic;
    }

}
