import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenMercadoPago } from '../model/TokenMercadoPago';
import { environment } from './../../environments/environment';
import { Pedido } from './../model/Pedido';

@Injectable({
  providedIn: 'root'
})
export class MercadoPagoService {
  constructor(
    private http: HttpClient
  ) {
    // this.init();
  }
  getApiURL() {
    return environment.apiUrl;
  }
  public getToken(pedido: Pedido): Promise<TokenMercadoPago> {

    return this.http.post(this.getApiURL() + "mercado-pago/cobro", pedido).toPromise().then((r: TokenMercadoPago) => {
      return r;
    });
  }
}
