import { Descriptivo } from './Descriptivo';
import { ProfilePic } from './ProfilePic';


export class Promocion extends Descriptivo {
    constructor(id?: number, codigo?: string, descripcion?: string,
        public banner: ProfilePic = new ProfilePic(null, 'assets/images/default_profile.png'),
        public detalle?: string,
        public terminos?: string,
        public productosIncluidos: Descriptivo[] = []
    ) {
        super(codigo, descripcion, id);

    }

    public static fromData(data: any): Promocion {
        if (!data) return null;
        const o: Promocion = new Promocion(
            data.id,
            data.codigo,
            data.descripcion,
            ProfilePic.fromData(data.banner),
            data.detalle,
            data.terminos,
            data.productosIncluidos.map(c => Descriptivo.fromData(c))
        );

        return o;

    }
    public get bannerUrl() {
        return this.banner ? this.banner.picPathVersion : 'assets/img/default_promo.png'
    }

}