import * as moment from 'moment';
export class Horario {

    private diasEnum = ["LU", "MA", "MI", "JU", "VI", "SA", "DO"]
    public getDiasSTR() {

        let str = this.dias.map(d => this.diasEnum[d]).join("-");
        if (this.dias.length == this.diasEnum.length) {
            str = "Todos los días"
        }
        return str;
    }
    public getHorarioSTR() {
        let hDesde = this.desde ? moment(this.desde).format("HH:mm") : null;
        let hHasta = this.hasta ? moment(this.hasta).format("HH:mm") : null;
        return !hDesde ? "SIN_HORARIO" : "De " + hDesde + (hHasta ? " hasta " + hHasta : "");

    }
    private getTime(dateTime: moment.Moment, format): moment.Moment {
        return moment({ h: dateTime.hours(), m: dateTime.minutes(), format: format });
    }
    public aplica(date: Date) {
        return this.esDia(date) && this.esHorario(date);
    }
    public esDia(date) {
        const day = moment(date).isoWeekday() - 1;
        return this.dias.some(d => d == day);
    }
    public esHorario(date) {
        var format = "hh:mm:ss";
        var time = this.getTime(moment(date), format);
        var desde = this.getTime(moment(this.desde), format);
        var hasta = this.getTime(moment(this.hasta), format);
        return time.isBetween(desde, hasta);
    }
    constructor(
        public desde?: Date,
        public hasta?: Date,
        public dias: number[] = [],

    ) {

    }
    public static fromData(data: any): Horario {
        if (!data) return null;
        const o: Horario = new Horario(

            data.desde ? new Date(data.desde) : null,
            data.hasta ? new Date(data.hasta) : null,
            data.dias ? data.dias : []
        );

        return o;

    }

}