import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { AlertController, IonSearchbar, ModalController, ToastController } from '@ionic/angular'
import { Storage } from '@ionic/storage'
import * as moment from 'moment'
import { Subscription } from 'rxjs'
import { filter, first } from 'rxjs/operators'
import { ModalPedidoComponent } from 'src/app/components/modal-pedido/modal-pedido.component'
import { GastoPedido } from 'src/app/model/GastoPedido'
import { ItemPedido } from 'src/app/model/ItemPedido'
import { Mesa } from 'src/app/model/Mesa'
import { Pedido } from 'src/app/model/Pedido'
import { Ubicacion } from 'src/app/model/Ubicacion'
import { MenuService } from 'src/app/services/menu.service'
import { PedidoService } from 'src/app/services/pedido.service'
import { ProductoPresenterService } from 'src/app/services/producto-presenter.service'
import { ProductoService } from 'src/app/services/producto.service'
import { LoginComponent } from '../login/login.component'
import { environment } from './../../../environments/environment'
import { CostoDelivery } from './../../model/CostoDelivery'
import { Descriptivo } from './../../model/Descriptivo'
import { Distancias } from './../../model/Distancias'
import { AuthService } from './../../services/auth.service'
import { LocalService } from './../../services/local.service'
import { LocalizacionService } from './../../services/localizacion.service'
import { MercadoPagoService } from './../../services/mercado-pago.service'
import { MesaService } from './../../services/mesa.service'
import { NovedadService } from './../../services/novedades.service'
@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.page.html',
    styleUrls: ['./checkout.page.scss'],
})
export class CheckoutPage implements OnInit, OnDestroy, AfterViewInit {
    loading: boolean
    pedido: Pedido = new Pedido()
    mesas: Mesa[] = []
    mesaActual: Mesa
    public distancia: Distancias
    private _metodoPago: string = 'E'
    cambioMesa: boolean = false
    mesaSeleccionada: Mesa
    public get metodoPago(): string {
        return this._metodoPago
    }
    public set metodoPago(v: string) {
        this._metodoPago = v
    }

    @ViewChild('direccionAuto', { read: IonSearchbar })
    public searchElementRef: IonSearchbar

    horarioEnvio: Date
    public efectivo = new Descriptivo('E', 'Efectivo')

    GoogleAutocomplete: google.maps.places.AutocompleteService

    autocompleteItems: any[]
    buscando: boolean = false
    itemDireccion: any
    public costo: CostoDelivery = {}

    constructor(
        private storage: Storage,
        private modalController: ModalController,
        private pedidoService: PedidoService,
        private mercadoPagoService: MercadoPagoService,
        private router: Router,
        public authService: AuthService,
        public localService: LocalService,
        private toastController: ToastController,
        private productoPresenter: ProductoPresenterService,
        private menuService: MenuService,
        private localizacionService: LocalizacionService,
        private productoService: ProductoService,
        private ngZone: NgZone,
        private novedadService: NovedadService,
        private zone: NgZone,
        private mesaService: MesaService,
        private alertCtrl: AlertController
    ) {
        this.GoogleAutocomplete = new google.maps.places.AutocompleteService()
    }
    public sub: Subscription
    ngOnInit() {
        this.initPedido()
    }

    initPedido = async () => {
        this.pedidoService.ready
        .pipe(
          filter((v) => v == true),
          first()
        ).subscribe(() => {
            this.pedidoService.pedido.pipe(filter((p) => p != null)).subscribe( async (p) => {
            
                if (!this.menuService.menuSeleccionado || !this.localService.local) {
                    if (!p.local || !p.menuId) this.router.navigate(['landing'])
                    await this.localService.seleccionarLocal(p.local.id)
                    await this.menuService.seleccionarMenu(p.menuId, p.local.id)
                }
                this.pedido = p ? p : new Pedido()
                if (!this.pedido.local) this.pedido.local = this.localService.local
    
                if (this.pedido.local) {
                    this.mesaService.getListByLocal(this.pedido.local.id).then((m) => {
                        this.mesas = m
                        if (this.pedido.mesaId) {
                            this.mesaActual = this.mesas?.find((mesa) => mesa.id == this.pedido.mesaId)
                        }
                    })
                }
    
                this.localizacionService.costoObs.subscribe((costo) => {
                    if (costo == undefined && this.pedido.tipoEntrega.codigo == 'D') {
                        this.error('Delivery no disponible')
                    } else {
                        this.zone.run(() => {
                            this.costo = costo
                        })
                    }
                })
                this.pedido.entrega = new Ubicacion()
                this.costo = {}
    
                this.authService.getAutenticateState().subscribe((r) => {
                    if (!this.authService.esUserDefault()) {
                        if (!this.pedido.email) {
                            this.pedido.email = this.authService.usuario.email
                        }
                        if (!this.pedido.nombreContacto) {
                            this.pedido.nombreContacto = this.authService.usuario.nombre
                        }
                        if (!this.pedido.telefonoContacto) {
                            this.pedido.telefonoContacto = this.authService.usuario.telefono
                        }
                    }
                })
            })
        })
        
        

        this.authService.getAutenticateState().subscribe((r) => {
            if (!this.authService.esUserDefault()) {
                if (!this.pedido.email) {
                    this.pedido.email = this.authService.usuario.email
                }
                if (!this.pedido.nombreContacto) {
                    this.pedido.nombreContacto = this.authService.usuario.nombre
                }
                if (!this.pedido.telefonoContacto) {
                    this.pedido.telefonoContacto = this.authService.usuario.telefono
                }
                this.pedido.descuento = this.authService.usuario.descuento
            }
        })
    }

    ngAfterViewInit() {
        this.checkPrecios().then((error) => {
            if (error) {
                this.error('Algunos productos del carrito cambiaron. Por favor revise el carrito.')
            }
        })
    }
    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe()
        this.pedido.gastos = []
        this.pedido.entrega = {}
        this.pedidoService.guardarPedido(this.pedido)
    }

    public getPlaceAutocomplete() {
        setTimeout(() => {
            if (!this.pedido.entrega.direccion || this.pedido.entrega.direccion.length <= 3) {
                this.autocompleteItems = []
                return
            }

            let request: google.maps.places.AutocompletionRequest = {
                input: this.pedido.entrega.direccion,
                componentRestrictions: { country: 'AR' },
                types: ['address'],
            }

            this.GoogleAutocomplete.getPlacePredictions(request, (predictions, status) => {
                this.autocompleteItems = []
                this.buscando = true
                this.ngZone.run(() => {
                    predictions?.length &&
                        predictions.forEach((prediction) => {
                            this.autocompleteItems.push(prediction)
                        })
                })
            })
        }, 500)
    }

    SelectSearchResult(item) {
        this.pedido.entrega.direccion = item.description
        this.autocompleteItems = []
        this.buscando = false
        this.itemDireccion = item
        this.actualizarCosto(item)
    }

    actualizarCosto(item) {
        this.pedido.gastos = []
        this.localizacionService.actualizarCosto(this.pedido.local.id, item.place_id)
    }

    public get admitePedido() {
        return this.localService.local?.admitePedido && !environment.soloMenu
    }
    public updateSeleccion(val: CustomEvent) {
        this.costo = {}
        this.pedido.gastos = []
        this.pedido.tipoEntrega = new Descriptivo(val.detail?.value, '')
        if (this.pedido.tipoEntrega?.codigo == 'P') {
            this.pedido.entrega = new Ubicacion()
        }
    }
    goToProducto = (event, item) => {
        this.productoPresenter.goToProducto(event, item.id, this.pedido.menuId, this.pedido.local.id)
    }
    async error(m: string) {
        const t = await this.toastController.create({
            message: m,
            duration: 3000,
            position: 'middle',
        })
        t.present()
    }
    public pagoMercadoPago() {
        if (this.metodoPago == 'MP' && this.pedido && this.pedido.itemsPedido && this.pedido.itemsPedido.length > 0) {
            this.loading = true
            this.mercadoPagoService
                .getToken(this.pedido)
                .then((r) => {
                    this.pedido.trxId = r.trxId
                    this.pedidoService.guardarPedido(this.pedido)
                    window.location.href = r.startingPoint
                })
                .catch(async (e) => {
                    this.error('<p>Hubo un error creando el link con Mercado Pago.</p><p> Intente otra forma de pago.</p>')
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
    async openModal() {
        this.loading = true
        if (this.costo && !this.pedido.gastos.find((g) => g.tipo.codigo === 'ENV')) {
            this.pedido.gastos.push(new GastoPedido('Costo de Envío', this.costo.costo, new Descriptivo('ENV')))
        }
        this.pedido.metodoPago = new Descriptivo(this.metodoPago)
        if (!this.pedido.metodoPago) {
            this.error('Debe seleccionar un metodo de pago')
            this.loading = false
            return
        }

        if (this.pedido.tipoEntrega.codigo != 'M') {
            if (!this.pedido.nombreContacto || !this.pedido.email) {
                this.error('Por favor indicanos un email y un nombre de contacto')
                this.loading = false
                return
            }

            if (!this.pedido.telefonoContacto) {
                this.error('Por favor indicanos un teléfono de contacto')
                this.loading = false
                return
            }
        }

        this.pedido.local = await this.localService.local
        if (!this.menuService.menuSeleccionado) {
            this.error('Debe seleccionar un menú')
            this.loading = false
            return
        }
        this.pedido.menuId = this.menuService.menuSeleccionado.id
        this.pedido.pushToken = await this.authService.getPush()
        this.pedidoService.updatePedido(this.pedido)
        await this.pedidoService.registrarPedido(this.pedido).then( async pedido => {
            if (!pedido) {
                this.loading = false
                this.error('<p>Error creando el pedido. </p><p>Intente mas tarde</p>')
                return null
            } else {
                this.pedido = pedido
            }
            
            if (this.metodoPago == 'MP') {
                this.pagoMercadoPago()
            } else {
                const modal = await this.modalController.create({
                    component: ModalPedidoComponent,
                    cssClass: 'modal-class',
    
                    componentProps: {
                        pedido: pedido.numeroPedido,
                        hora: pedido.tipoEntrega?.codigo == 'D' ? moment(this.horarioEnvio).format('HH:mm') : null,
                    },
                })
                this.loading = false
                await modal.present()
    
                await modal.onWillDismiss().then((data) => {
                    this.pedidoService.guardarPedido(pedido)
                    this.pedidoService.resetPedido(pedido)
                    // this.pedidoService.clearPedido()
                    this.router.navigate(['/mis-pedidos'])
                })
            }
        })
        .catch(async (e) => {
            this.loading = false
            this.error('<p>Error creando el pedido. </p><p>Intente mas tarde</p>')
            return null
        })

    }

    async presentToast(message: string) {
        // Pasar a servicio
        const toast = await this.toastController.create({
            message,
            duration: 2000,
        })
        toast.present()
    }
    public quitarItemPedido(item: ItemPedido) {
        this.pedidoService.removerFromCart(item)
        this.pedido.itemsPedido = this.pedido.itemsPedido.filter((i) => i.id !== item.id)
    }
    async checkout() {
        let error = await this.checkPrecios()

        if (error) {
            this.presentToast('Algunos productos del carrito cambiaron. Por favor revise el carrito.')
        } else if (!this.metodoPago) {
            this.presentToast('Tenés que elegir una forma de pago.')
        } else if (!this.horarioEnvio && this.pedido.tipoEntrega?.codigo == 'D') {
            this.presentToast('Tenés que elegir un horario de envío.')
        } else if (!this.pedido?.entrega?.direccion && this.pedido.tipoEntrega?.codigo == 'D') {
            this.presentToast('Tenés que elegir una dirección de entrega')
        } else if (!this.pedido?.email && this.pedido.tipoEntrega?.codigo != 'M') {
            this.presentToast('Por favor ingresá un mail de contacto. Te enviaremos ahí el detalle del pedido.')
        } else if (!this.pedido?.mesaId && this.pedido.tipoEntrega?.codigo == 'M') {
            this.presentToast('Por favor elegí una mesa para que te llevemos tu pedido.')
        } else if (!this.validateTimeCheckout()) {
            this.presentToast('El horario de envio debe ser superior a 30 minutos desde el horario del pedido')
        } else {
            this.openModal()
        }
    }

    async login() {
        const modal = await this.modalController.create({
            component: LoginComponent,
            cssClass: 'modal-login',
            componentProps: {
                modal: true,
            },
        })

        await modal.present()
    }

    isMobile() {
        return window.innerWidth <= 640
    }

    getDisabled(): boolean {
        return this.pedido.tipoEntrega.codigo == 'D' && !this.costo.costo
    }


    async checkPrecios(): Promise<boolean> {
        let error = false
        await this.novedadService.check()

        this.pedido.itemsPedido.forEach((item) => {
            let p = this.productoService.getProductoCache(item.producto.id)

            if (item.precioLista != p.precioLista || item.precioDescuento != p.precioDescuento) {
                error = true
                item.precioLista = p.precioLista
                item.precioDescuento = p.precioDescuento
            }
        })

        return error
    }

    getCurrentTime(): string {
        const date = new Date()

        let minutes: number

        if (date.getMinutes() > 45) {
            minutes = 30
        } else if (date.getMinutes() <= 45 && date.getMinutes() > 30) {
            minutes = 15
        } else if (date.getMinutes() <= 30 && date.getMinutes() > 15) {
            minutes = 0
        } else minutes = 45

        let hour: string

        if (minutes <= 30) {
            hour = date.getHours() == 23 ? '00' : date.getHours() + 1 < 10 ? `0${(date.getHours() + 1).toString()}` : date.getHours().toString()
        } else hour = date.getHours() < 10 ? `0${date.getHours().toString()}` : date.getHours().toString()

        const minutesStr = minutes < 10 ? `0${minutes.toString()}` : minutes.toString()
        return `${hour}:${minutesStr}`
    }

    validateTime(event: any) {
        const now = moment().add(30, 'minutes')
        if (now.isAfter(event.detail.value)) {
            this.error('El horario de envio debe ser superior a 30 minutos desde el horario del pedido')
        }
    }

    validateTimeCheckout() {
        const now = moment().add(30, 'minutes')
        if (this.pedido.tipoEntrega.codigo == 'D' && now.isAfter(this.horarioEnvio)) {
            return false
        } else return true
    }

    async cambiarMesa(event) {
        event.stopPropagation()
        this.cambioMesa = true
    }

    cambiar() {
        this.mesaActual = { ...this.mesaSeleccionada }
        this.pedido.mesaId = this.mesaActual.id
        this.pedidoService.updatePedido(this.pedido)
        this.mesaSeleccionada = null
        this.cambioMesa = false
    }

    compareWith(o1: Mesa, o2: Mesa) {
        return o1 && o2 ? o1.id === o2.id : o1 === o2
    }

    public get tieneMp(): Boolean {
        return this.pedido?.local?.tieneMp
    }
}
