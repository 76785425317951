import { animate, style, transition, trigger } from '@angular/animations'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { IonContent, IonInput, MenuController, ModalController } from '@ionic/angular'
import { combineLatest, Subscription } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'
import { Categoria } from 'src/app/model/Categoria'
import { MenuDelDia } from 'src/app/model/MenuDelDia'
import { Restriccion } from 'src/app/model/Restriccion'
import { ContextService } from 'src/app/services/context.service'
import { MenuService } from 'src/app/services/menu.service'
import { PedidoService } from 'src/app/services/pedido.service'
import { ProductoPresenterService } from 'src/app/services/producto-presenter.service'
import { ProductoService } from 'src/app/services/producto.service'
import { RankingVentasService } from 'src/app/services/ranking-ventas.service'
import { environment } from './../../../environments/environment'
import { ModalFiltroComponent } from './../../components/modal-filtro/modal-filtro.component'
import { ItemPedido } from './../../model/ItemPedido'
import { ProductoCabecera } from './../../model/ProductoCabecera'
import { ProductoRanking } from './../../model/ProductoRanking'
import { Promocion } from './../../model/Promocion'
import { LocalService } from './../../services/local.service'
import { MesaService } from './../../services/mesa.service'
import { NavigationService } from './../../services/navigation.service'
import { NovedadService } from './../../services/novedades.service'
import { RestriccionService } from './../../services/restriccion.service'

@Component({
    selector: 'app-menu',
    templateUrl: './menu.page.html',
    styleUrls: ['./menu.page.scss'],
    animations: [
        trigger('slide', [
            transition(':enter', [style({ opacity: 0 }), animate('0.2s ease-in-out', style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate('0.2s ease-in-out', style({ opacity: 0 }))]),
        ]),
        trigger('fromTop', [
            transition(':enter', [style({ "margin-top": "-2em" }), animate('0.2s ease-in-out', style({ "margin-top": 0 }))]),
            transition(':leave', [style({"margin-top": "0" }), animate('0.2s ease-in-out', style({ "margin-top": "-2em" }))]),
        ]),
    ],
})
export class MenuPage implements OnInit {
    @ViewChild('search') set content (content: IonInput) {
        if (content) {
            setTimeout(() => {
                content.setFocus()
            }, );
        }
    }

    categoriaEnPromocion: Categoria = {}
    //enPromocion: ItemPedido[]= []
    loading: boolean
    filtroModificado: boolean = false
    @ViewChild(IonContent) mainContent
    //categories: any = [];
    public filterIsShowing:boolean = false;
    srchInput:FormControl = new FormControl();
    private _seccion: string
    private controlSub : Subscription;
    public get seccion(): string {
        return this._seccion
    }
    public set seccion(v: string) {
        if (v != this._seccion) {
            this._seccion = v
            this.mainContent?.scrollToTop()
        }
    }

    menuDelDia: MenuDelDia
    promociones: Promocion[] = []
    restricciones: Restriccion[] = []
    masVendidos: Categoria
    private sub: Subscription
    public searchFC: FormControl = new FormControl()
    constructor(
        private contextService: ContextService,
        public menuService: MenuService,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private localService: LocalService,
        private pedidoService: PedidoService,
        private menuController: MenuController,
        private activatedRoute: ActivatedRoute,
        private navigationService: NavigationService,
        private novedadesService: NovedadService,
        public productoService: ProductoService,
        public restriccionService: RestriccionService,
        public mesaService: MesaService,
        private productoPresenter: ProductoPresenterService,
        public rankingVentasService: RankingVentasService,
        public modalController: ModalController
    ) {}
    public get pedido() {
        return this.pedidoService.pedidoActual
    }
    public isMobile() {
        return window.innerWidth <= 640
    }
    public isDesktop() {
        return window.innerWidth >= 960
    }
    ngOnInit() {
        this.loading = true
        this.menuController.enable(true)
        this.searchFC.setValue('');
        if(this.controlSub) this.controlSub.unsubscribe();
        this.controlSub = this.searchFC.valueChanges.pipe(debounceTime(200)).subscribe(v=>{
            if(v && this.seccion != 'todos') {this.seccion = 'todos';}
        })
        combineLatest([this.activatedRoute.queryParams, this.activatedRoute.params])
            .pipe(
                map((v) => {
                    return { queryParams: v[0], params: v[1] }
                })
            )
            .subscribe(async (params) => {
                let start: Promise<any>[] = []
                const idProducto = Number(params.params['idProducto'])
                const idMenu = params.params['idMenu']
                const idLocal = params.params['idLocal']

                if (idLocal && this.localService.local?.id != idLocal) {
                    await this.localService.seleccionarLocal(idLocal)
                    if (idMenu && this.menuService.menuSeleccionado?.id != idMenu) {
                        await this.menuService.seleccionarMenu(idMenu, idLocal)
                    }
                }

                if (idProducto && idMenu && idLocal) {
                    start.push(this.productoPresenter.goToProducto(null, idProducto, idMenu, idLocal))
                }

                Promise.all(start)
                    .then((l) => {
                        if (!this.menuService.menuSeleccionado || !this.localService.local) {
                            this.navigationService.goToLanding()
                            return
                        }
                        if (params.queryParams.idCategoria && this.seccion != params.queryParams.idCategoria && this.menuDelDia) {
                            this.seccion = this.menuDelDia.categorias.filter((c) => c.id == params.queryParams.idCategoria)[0]?.descripcion || 'todos'
                        } else {
                            this.seccion = 'todos'
                        }
                        if (this.menuService.menuSeleccionado.mostrarMasVendidos) {
                         this.rankingVentasService.getRanking(this.menuService.menuSeleccionado.id, this.localService.local.id).then((r) => {
                                this.masVendidos = new Categoria()
                                this.masVendidos.descripcion = 'Más vendidos'
                                this.masVendidos.items = []

                                r.forEach((producto: ProductoRanking) => {
                                    const p = this.productoService.getProductoCache(producto.productoId)
                                    let i = new ItemPedido()
                                    i.producto = ProductoCabecera.fromData(p)
                                    i.precioLista = p.precioLista
                                    i.precioDescuento = p.precioDescuento
                                    this.masVendidos.items = [...this.masVendidos.items, i]
                                })
                            })
                        }
                    })
                    .catch((e) => {
                        this.navigationService.goToLanding()
                    })
            })
        if (this.sub) this.sub.unsubscribe()
        this.sub = this.menuService.menuSeleccioandoObs.subscribe((r) => {
            this.loading = true
            if (!r) {
                this.menuDelDia = null
            } else if (!this.menuDelDia || r?.id != this.menuDelDia.id) {
                this.menuService.getCategories(r.key).subscribe((data: MenuDelDia) => {
                    this.menuDelDia = data
                    // this.menuService.getPromociones().then((r) => {
                    //     this.promociones = r
                    // })
                    this.categoriaEnPromocion.items = this.menuDelDia.getPromociones()
                    this.categoriaEnPromocion.descripcion = 'Promociones'
                    this.loading = false
                })
            }
        })

        this.restriccionService.getHabilitados().then((r: Restriccion[]) => {
            this.restricciones = r.map((res) => {
                const r = Restriccion.fromData(res)
                if (!r.excluyente) r.seleccionada = true
                return r
            })
        })
    }
    public clean() {
        this.searchFC.setValue('')
        this.restricciones = [...this.restricciones.map((restriccion) => {
            if (restriccion.excluyente)
                restriccion.seleccionada = false
            else restriccion.seleccionada = true
            return restriccion
        })]
        this.filtroModificado = false
        this.filterIsShowing = false;
    }

    goToProducto = async (event, item) => {
        this.productoPresenter.goToProducto(event, item.id, this.menuDelDia.id, this.menuDelDia.idLocal)
    }
    ionViewWillEnter() {
        // this.novedadesService.recheck();
    }
    public get admitePedido() {
        return this.localService.local?.admitePedido && !environment.soloMenu
    }
    verDetallePromo(p: Promocion) {
        this.contextService.set('promo', p)
        this.router.navigate(['/promocion-detalle'])
    }
    public quitarItemPedido(item: ItemPedido) {
        this.pedidoService.removerFromCart(item)
    }
    checkout(): void {
        this.router.navigate(['/checkout'])
    }

    async filtrar() {
        const modal = await this.modalController.create({
            component: ModalFiltroComponent,
            cssClass: 'modal-filtro',
            backdropDismiss: true,
            componentProps: {
                searchFC: this.searchFC.value,
                restricciones: this.restricciones,
            },
        })
        this.loading = false
        await modal.present()

        modal.onDidDismiss().then((f) => {
            if (f?.data?.searchFC) {
                this.searchFC.setValue(f.data.searchFC)
            } 
            if (f?.data?.restricciones) {
                this.restricciones = [...f.data.restricciones]
            }
            if (f?.data?.filtroModificado) {
                this.filtroModificado = f.data.filtroModificado
            }
            if (!this.searchFC?.value) {
                this.filterIsShowing = false;
            }
        })
    }

    filterShowing() {
        if (!this.searchFC?.value) {
            this.filterIsShowing = !this.filterIsShowing

        }
    }
}
