import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ItemPedido } from 'src/app/model/ItemPedido';
import { ProductoService } from 'src/app/services/producto.service';
import { Producto } from './../../model/Producto';
import { LocalService } from './../../services/local.service';
import { MenuService } from './../../services/menu.service';
import { PedidoService } from './../../services/pedido.service';
import { ProductoPresenterService } from './../../services/producto-presenter.service';

@Component({
	selector: 'item-pedido',
	templateUrl: './item-pedido.component.html',
	styleUrls: ['./item-pedido.component.scss'],
})
export class ItemPedidoComponent implements OnInit {

	constructor(private router: Router, private menuService: MenuService, public productoService: ProductoService,
		public localService: LocalService,
		private pedidoService: PedidoService, private productoPresenter: ProductoPresenterService) { }
	@Input()
	public item: ItemPedido;
	@Input()
	public recomendacion: boolean = false;
	public change: boolean = false;

	@Input()
	public disabled: boolean = false;
	@Input()
	public deletable: boolean = true;

	@Output()
	public onQuitar: EventEmitter<ItemPedido> = new EventEmitter();

	@Output()
	public onItemClickHandler: EventEmitter<ItemPedido> = new EventEmitter();
	ngOnInit() {

		this.pedidoService.onItemsChange.pipe(filter(f => f != null)).subscribe((i: ItemPedido) => {
			if (i.producto?.id == this.item.producto?.id) {
				this.valueChange();
			}

		})
	}
	quitarItemPedido(event) {
		event.stopPropagation();
		this.onQuitar.emit(this.item);
	}
	addToCart(producto: Producto, event?) {
		this.pedidoService.addToCart(producto);
		event.stopPropagation();
	}
	@Input()
	public onItemClick = async (event, producto) => {
		this.productoPresenter.goToProducto(event, producto.id, this.menuService.menuSeleccionado.id,
			(await this.localService.getSeleccionado()).id);
		event.stopPropagation();

	}

	public valueChange() {
		this.change = true;
		setTimeout(() => {
			this.change = false;
		}, 200)
	}

}
