import { NumberSymbol } from '@angular/common'

export class ProductoRanking {
    constructor(public productoId?: number, public importe?: number, public cantidad?: number) {}

    public static fromData(data): ProductoRanking {
        if (!data) return;
        const o = new ProductoRanking(data.productoId, data.importe, data.cantidad)
        return o
    }
}
