import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QRScanner } from '@ionic-native/qr-scanner/ngx';
import { NavController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Pedido } from 'src/app/model/Pedido';
import { PedidoService } from './../../services/pedido.service';

@Component({
  selector: 'app-agregar-pedido',
  templateUrl: './agregar-pedido.component.html',
  styleUrls: ['./agregar-pedido.component.scss'],
})
export class AgregarPedidoComponent implements OnInit, OnDestroy {

  constructor(private pedidoService: PedidoService,
    private route: ActivatedRoute,
    private toastController: ToastController,
    private router: Router,
    private qrScanner: QRScanner,
    private navController: NavController,
  ) { }


  private _esPager: boolean;
  public get esPager(): boolean {
    return this._esPager;
  }
  public set esPager(v: boolean) {
    this._esPager = v;
  }
  private scanSub;
  private _idLocal: number;
  public get idLocal(): number {
    return this._idLocal;
  }
  public set idLocal(v: number) {
    this._idLocal = v;
  }

  private _numeroPedido: string;
  public get numeroPedido(): string {
    return this._numeroPedido;
  }
  public set numeroPedido(v: string) {
    this._numeroPedido = v;
  }

  async toast(m: string) {
    const t = await this.toastController.create({
      message: m,
      duration: 3000,
      position: "middle"
    });
    t.present();
  }
  private _codigoVerificacion: string;
  public get codigoVerificacion(): string {
    return this._codigoVerificacion;
  }
  public set codigoVerificacion(v: string) {
    this._codigoVerificacion = v;
  }

  public async agregarPedido(back: boolean = true): Promise<Pedido> {
    let ped = "";
    if (this.numeroPedido.toUpperCase().startsWith("T")) {
      ped = this.numeroPedido.toUpperCase().replace("T", "");
      this.esPager = true;
    } else {
      ped = this.numeroPedido;
      this.esPager = false;
    }
    return this.pedidoService.asociarPedido(ped, this.codigoVerificacion.toUpperCase(), this.esPager, this.idLocal).then(async p => {
      this.pedidoService.agregarPedido(p);
      await this.toast("Pedido Asociado");
      if (back)
        this.navController.back();
      return p;
    }).catch(e => {
      this.toast(e?.error?.message ? e.error.message : "No se pudo asociar el pedido");
      return null;
    })
  }

  private routeSub: Subscription;
  ngOnInit() {
    this.routeSub = this.route.params.subscribe(params => {
      this.idLocal = <number>params["idLocal"];
      this.numeroPedido = <string>params["numeroPedido"];
      this.codigoVerificacion = <string>params["codigoVerificacion"];
      if (this.numeroPedido && this.codigoVerificacion) {
        this.agregarPedido(false).then(r => {
          //this.routeSub.unsubscribe();
          if (r)

            this.router.navigate(['pedido'], { queryParams: { idPedido: r.id }, replaceUrl: true })
        });

      }

    });

  }
  public activarScanner() {
    this.scanSub = this.qrScanner.scan().subscribe((text: string) => {
      this.qrScanner.hide(); // hide camera preview
      this.scanSub.unsubscribe(); // stop scanning
    });
  }
  stopScanning() {
    (this.scanSub) ? this.scanSub.unsubscribe() : null;
    this.scanSub = null;
    //(window.document.querySelector('ion-app') as HTMLElement).classList.remove('cameraView');
    this.qrScanner.hide();
    this.qrScanner.destroy();
  }
  ngOnDestroy() {
    if (this.routeSub) this.routeSub.unsubscribe();
  }

  isMobile() {
    return window.innerWidth <= 640;
  }
}
