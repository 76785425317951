import { Parametrico } from 'src/app/common/model/Parametrico';
import { ProfilePic } from './ProfilePic';

export class Restriccion extends Parametrico {
    constructor(
        id?: number,
        codigo?: string,
        descripcion?: string,
        habilitado: boolean = true,
        esSistema: boolean = false,
        peso: number = 99,
        esDefault: boolean = false,
        public codigoIdioma: string = 'ES',
        public seleccionada: boolean = false,
        public icono?:ProfilePic,
        public excluyente: boolean = false,
    ) {
        super(id, codigo, descripcion, habilitado, esSistema, peso)
    }
    public tipoParametro(): string {
        return '$MODELNAMELOW'
    }
    public static fromData(data: any): Restriccion {
        if (!data) return null
        const o: Restriccion = new Restriccion(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,
            data.esDefault,
            data.codigoIdioma,
            
        )
        o.icono = ProfilePic.fromData(data.icono);
        o.excluyente = data.excluyente ?? false;
        return o
    }

    public get imageUrl(){
        return this.icono?.picPath || null
    }
}
