import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private skippedError: string[] = ["/novedades"];
    ;
    constructor(private authenticationService: AuthService) {
        //this.loadingService = LoadingService.injector.get(LoadingService);
    }
    private inSkipped(url: string) {
        return this.skippedError.some(s => url.includes(s));
    }
    handleRequest(next, request): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    //this.loadingService.susLoadingCount();
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                return throwError(error);
            }));;
    }
    private addToken(request: HttpRequest<any>) {
        const req = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.authenticationService.tokenBackend}`
            }
        });
        return req;
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        if (this.authenticationService.tokenBackend) {
            if (!request.url.includes(".amazonaws.com")) {
                request = this.addToken(request);
            }
            return this.handleRequest(next, request);

        } else if (request.url.includes("/login") || request.url.includes("/public") || request.url.includes("/register") || request.url.endsWith("/reauthenticate") || (request.url.includes("assets") && request.url.includes("i18n"))) {
            const xhr = request.clone({
                withCredentials: true
            });
            return this.handleRequest(next, xhr);
        } else {
            return this.authenticationService.getAutenticateState().pipe(filter(r => r == AuthService.LOGUEADO), take(1), switchMap(() => {
                request = this.addToken(request);
                return this.handleRequest(next, request);
            }))
        }
        //this.loadingService.addLoadingCount();



    }

}