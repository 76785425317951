import { Restriccion } from 'src/app/model/Restriccion';
import { Adicionales } from './Adicionales';
import { ProductoCabecera } from './ProductoCabecera';

export class ItemPedido {
	id: number;
	producto: ProductoCabecera;
	descuentoAplicado?: string;
	categoria?: string;
	precioLista?: number;
	precioDescuento?: number;
	descuento?: number;
	cantidad: number = 1;
	adicionales?: Adicionales[];
	visible?: boolean;
	peso?: number;
	nuevo: boolean = false
	destacado: boolean = false
    

	public static fromData(data) {
		if (!data) return;
		const o = new ItemPedido();
		Object.assign(o, data);
		o.producto = ProductoCabecera.fromData(data.producto)
		o.visible = data.visible;
		o.peso = data.peso;
		o.nuevo = data.nuevo ?? false
		o.destacado = data.destacado ?? false
		return o;
	}

	public get total() {
		return this.cantidad * (this.precioDescuento ? this.precioDescuento : this.precioLista);
	}

	public get unidad() {
		return this.producto?.unidad ? this.producto.unidad.descripcion : "u";
	}
	public get imagen(){
		return this.producto.imagen
	}
	public aplicaRestricciones(restricciones:Restriccion[], restriccionesExcluyentes: Restriccion[]){
		return this.producto.aplicaRestricciones(restricciones, restriccionesExcluyentes);
	}

	public get sinUnidad() {
		return this.producto?.sinUnidad
	}
}
