import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class MessagingService {

    private _token: string;
    public tokekObs: BehaviorSubject<string> = new BehaviorSubject(null);
    public get token(): string {
        return this._token;
    }
    public set token(v: string) {
        this._token = v;
        this.tokekObs.next(v);
    }

    constructor(private afMessaging: AngularFireMessaging) {

    }


    requestPermission() {
        return this.afMessaging.requestToken
            .subscribe(
                (token) => { this.token = token; },
                (error) => { },
            );

    }

    getMessages() {
        return this.afMessaging.messages;
    }

    deleteToken() {
        this.afMessaging.getToken
            .pipe(mergeMap(token => this.afMessaging.deleteToken(token)))
            .subscribe(
                (token) => {  },
            );
    }
}
