import { Component, EventEmitter, Input, OnInit, Output, SecurityContext } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { Local } from './../../model/Local'

@Component({
    selector: 'app-local-card',
    templateUrl: './local-card.component.html',
    styleUrls: ['./local-card.component.scss'],
})
export class LocalCardComponent implements OnInit {
    private _local: Local
    public get local(): Local {
        return this._local
    }
    @Input()
    public set local(v: Local) {
        this._local = v
        if (this._local) {
            const tel = this.local?.telefono?.trim().startsWith('54') ? this.local.telefono.trim() : '54' + this.local.telefono
            this.tel = this.sanitizer.bypassSecurityTrustUrl(`whatsapp://send?phone=${tel.trim()}`)
        }
    }

    @Input()
    public layout: 'horizontal' | 'vertical' = 'vertical'
    public tel: SafeUrl

    @Output()
    public onClick: EventEmitter<Local> = new EventEmitter()
    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {}
    public handleClick(event) {
        event?.stopPropagation && event.stopPropagation()
        this.onClick.emit(this.local)
    }
    public openMapsApp(event) {
        if (this.local?.ubicacion?.urlUbicacion) {
            window.open(this.sanitizer.sanitize(SecurityContext.URL, this.local.ubicacion.urlUbicacion))
        }
        event?.stopPropagation && event.stopPropagation()
    }
    public openWhatsapp(event) {
        window.open(this.sanitizer.sanitize(SecurityContext.URL, this.tel))

        event?.stopPropagation && event.stopPropagation()
    }
    public getWhatsapp() {}
}
