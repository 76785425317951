import { EstadoReserva } from 'src/app/model/EstadoReserva'
import { Descriptivo } from './Descriptivo'
import { Mensaje } from './Mensaje'

export class Reserva {
    constructor(
        public id?: number,
        public nombre?: string,
        public cliente?: Descriptivo,
        public fecha?: Date,
        public horario?: string,
        public cantidadPersonas: number = 1,
        public local?: Descriptivo,
        public seniaPaga?: boolean,
        public confirmar: boolean = false,
        public observaciones?: string,
        public emailContacto?: string,
        public telefonoContacto?: string,
        public asistencia?: string,
        public estado?: EstadoReserva,
        public mensajes: Mensaje[] = [],
        public numeroReserva?: string
    ) {}
    public static fromData(data: any): Reserva {
        if (!data) return null
        const o: Reserva = new Reserva(
            data.id,
            data.nombre,
            Descriptivo.fromData(data.cliente),
            data.fecha ? new Date(data.fecha) : null,
            data.horario,
            data.cantidadPersonas,
            Descriptivo.fromData(data.local),
            data.seniaPaga,
            data.confirmar,
            data.observaciones,
            data.emailContacto,
            data.telefonoContacto,
            data.asistencia,
            EstadoReserva.fromData(data.estado),
            data.mensajes ? data.mensajes.map((m) => Mensaje.fromData(m)) : [],
            data.numeroReserva
        )

        return o
    }

    public get email(): string {
        return this.emailContacto
    }
    public set email(v: string) {
        this.emailContacto = v
    }

    get telefono() {
        return this.telefonoContacto
    }
    get nombreReserva() {
        return (this.cliente ? this.cliente.descripcion : this.nombre) + ' x ' + this.cantidadPersonas
    }

    get descripcion() {
        return this.nombreReserva
    }

    get asistenciaDesc() {
        return this.asistencia ? (this.asistencia == 'A' ? 'Ausente' : 'Presente') : ''
    }
    public noFinalizado() {
        return this.estado?.codigo != 'C' && this.estado?.codigo != 'F' && this.estado?.codigo != 'R'
    }
}
