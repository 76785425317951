import { ItemPedido } from './ItemPedido';

export class Categoria {
    id?: number;
    descripcion?: string;
    peso?: number;
    items?: ItemPedido[];
    imagenPortada?: string

    public static fromData(data) {
        if (!data) return;
        var o = new Categoria();
        o.id = data.id;
        o.peso = data.pes;
        o.descripcion = data.descripcion;
        o.items = data.items ? data.items.map(i => ItemPedido.fromData(i)) : [];
        o.items = o.items.sort((a, b) => {
            return a.peso > b.peso ? 1 : -1;
        })
        o.imagenPortada = data.imagenPortada;
        return o;
    }
}

