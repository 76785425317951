import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: "root"
})
export class LanguageService {


    public language: BehaviorSubject<string> = new BehaviorSubject("ES");
    private _selectedLanguage: string = "ES";
    public get selectedLanguage(): string {
        return this._selectedLanguage;
    }
    public set selectedLanguage(v: string) {
        this._selectedLanguage = v;
        this.language.next(v);
    }

}