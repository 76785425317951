import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ItemPedido } from 'src/app/model/ItemPedido';
import { PedidoService } from 'src/app/services/pedido.service';
import { ProductoPresenterService } from 'src/app/services/producto-presenter.service';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.page.html',
  styleUrls: ['./pedido.page.scss'],
})
export class PedidoPage implements OnInit {
  loading: boolean;
  sugerencias: ItemPedido[] = [];

  public get pedido() {
    return this.pedidoService.pedidoSeleccionado;
  }

  @Input()
  public isModal: boolean = false;
  constructor(
    private pedidoService: PedidoService,
    private route: ActivatedRoute,
    private router: Router,
    private modalController: ModalController,
    private productoPresenter: ProductoPresenterService,
  ) {


  }

  public seguirComprando() {
    this.router.navigate(['/menu']);
    this.modalController.dismiss();

  }
  public quitarItemPedido(item: ItemPedido) {
    this.pedidoService.removerFromCart(item);
  }
  public get admitePedido() {


    return this.pedido?.local?.admitePedido && !environment.soloMenu;
  }
  private sub: Subscription;
  private routeSub: Subscription;

  public get size() {
    return this.isModal ? 12 : 4;
  }
  ngOnInit() {
    //this.loading = true;

    this.routeSub = this.route.queryParams.subscribe(async params => {
      if (params['idPedido']) {
        this.pedidoService.pedidoSeleccionado = await this.pedidoService.getById(<number>params['idPedido']);
      }
    })



  }
  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    if (this.routeSub) this.routeSub.unsubscribe();
  }
  checkout(): void {
    this.router.navigate(['/checkout']);
    this.modalController.dismiss();
  }

  goToProducto = (event, item) => {
		this.productoPresenter.goToProducto(event, item.id, this.pedido.menuId, this.pedido.local.id, true);

	}

  

}
