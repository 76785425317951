import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { BehaviorSubject, merge, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MenuDelDia } from "../model/MenuDelDia";
import { Producto } from "./../model/Producto";
import { NovedadService } from "./novedades.service";

@Injectable({
  providedIn: "root",
})
export class ProductoService {
  actualizarCacheDesdeMenu(menu: MenuDelDia) {
    menu.categorias.forEach((c) => {
      c.items.forEach((p) => {
        this._productos.set(p.producto.id, new Producto(Object.assign({}, p, p.producto)));
      });
    });
   // this.storage.set(environment.tokenKey + "_" + "productos", this._productos);
  }

  private _productos: Map<number, Producto> = new Map();
  public get productos(): Map<number, Producto> {
    return this._productos;
  }
  public set productos(v: Map<number, Producto>) {
    this._productos = v;
   // this.storage.set(environment.tokenKey + "_" + "productos", this._productos);
  }

  constructor(private http: HttpClient, private modalController: ModalController, private novedadesService: NovedadService, private storage: Storage) {
  /*  this.storage.get(environment.tokenKey + "_" + "productos").then((p) => {
      this._productos = p ? p : new Map();
    });*/
    this._productos = new Map();
  }
  private _productoSeleccionado: Producto;
  public productoObs: BehaviorSubject<Producto> = new BehaviorSubject(null);
  public get productoSeleccionado(): Producto {
    return this._productoSeleccionado;
  }
  public set productoSeleccionado(v: Producto) {
    this._productoSeleccionado = v;
    this.productoObs.next(v);
  }
  public getImagen(producto){
    return this.getProductoCache(producto.id)?.imagenPortada || 'assets/img/default_image.png'
  }
  getProductoCache(id: number): Producto {
    return this._productos.get(id);
  }


  getProductoById(id: number, idMenu: number, idLocal: number): Observable<Producto> {
    return merge(
      new Observable((obs) => {
        if (this._productos.has(id)) {
          obs.next(this._productos.get(id));
          obs.complete();
        }
      }),
      this.http.get(`${this.getApiURL()}api/producto?id=${id}&idMenu=${idMenu}&idLocal=${idLocal}`).pipe(
        map((p) => {
          const prod = new Producto(p);
          this._productos.set(id, prod);
          return prod;
        })
      )
    );
  }

  getProductos() {
    return this.http.get(`${this.getApiURL()}productos.json`);
  }

  getIngredientes() {
    return this.http.get(`${this.getApiURL()}ingredientes.json`);
  }
  public cleanCache() {
    this.productos = new Map();
  }
  getApiURL() {
    return environment.apiUrl;
  }
}
