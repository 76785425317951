import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  public modal: boolean = false;

  constructor(private auth: AuthService,
    private router: Router,
    public modalController: ModalController) { }

  ngOnInit() {
    // if (this.auth.hayLogueado()) {
    //   this.router.navigateByUrl('/register');
    // }
  }

  login(red: string) {

    this.auth.loginFirebase(red).then(resp => {
      this.auth.loginBackend().then(r => {
        if (!r?.usuario) {
          this.router.navigateByUrl('/register');
        }
        /* else {
           this.router.navigateByUrl('/landing');
         }*/
      })
      this.dismiss();
    });

  }
  dismiss() {
    this.modalController.dismiss();
  }
}
