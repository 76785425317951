import { ProductoRanking } from './../model/ProductoRanking'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root',
})
export class RankingVentasService {
    constructor(private http: HttpClient) {}

    getRanking(idMenu: number, idLocal: number): Promise<ProductoRanking[]> {
        return this.http
            .get(`${this.getApiURL()}ranking-ventas/app/?&idMenu=${idMenu}&idLocal=${idLocal}`)
            .toPromise()
            .then((p: any[]) => p.map((prod) => ProductoRanking.fromData(prod)))
    }

    getApiURL() {
        return environment.apiUrl 
    }
}
