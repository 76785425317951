import { Descriptivo } from './Descriptivo';
import { Disponibilidad } from './Disponibilidad';
import { ProfilePic } from './ProfilePic';


export class Menu extends Descriptivo {
    public key: MenuKey
    constructor(id?: number, codigo?: string, descripcion?: string,
        public detalle?: Descriptivo,
        public imagenPortada?: ProfilePic,
        public disponibilidad?: Disponibilidad,
        public idLocal?: number,
        public mostrarMasVendidos = false,


    ) {
        super(codigo, descripcion, id)
        this.key = new MenuKey(this.id, this.idLocal);
    }

    public static fromData(data: any): Menu {
        if (!data) return null;
        const o: Menu = new Menu(
            data.id,
            data.codigo,
            data.descripcion,
            data.detalle,
            ProfilePic.fromData(data.imagenPortada),
            Disponibilidad.fromData(data.disponibilidad),
            data.idLocal,
            data.mostrarMasVendidos,
        );

        return o;

    }

    public get bannerUrl() {
        return this.imagenPortada ? this.imagenPortada.picPathVersion : 'assets/img/default_image.png'
    }
    public esDisponible() {
        return this.disponibilidad ? this.disponibilidad.esDisponible(new Date()) : true;
    }
}
export class MenuKey {
    constructor(
        public idMenu,
        public idLocal
    ) { }

    public equals(key: MenuKey) {
        return key?.idMenu == this?.idMenu && key?.idLocal == this?.idLocal;
    }
    public get str() {
        return this.idMenu + "_" + this.idLocal;
    }
}